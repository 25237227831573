import React, { useEffect, useState } from "react";
import { TypingAnimation } from "./TypingAnimation"; // Adjust the path as needed

export function WordRotate({ isInputActive }) {
  const predefinedqueries = [
    "Turn a podcast episode into a blog post and social clips",
    "Build a project management workflow for remote teams",
    "Automate onboarding workflows for new hires",
    "Turn a YouTube video into a Twitter thread.",
    "Learn Python by building small projects with AI guidance.",
    "Connect Slack to Trello for automated task updates.",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    console.log(
      "WordRotate useEffect triggered. isInputActive:",
      isInputActive
    );

    if (isInputActive) {
      console.log("Input is active. Clearing interval to stop rotation.");
      return; // Do not set interval when input is active
    }

    const interval = setInterval(() => {
      console.log("Rotating to next query. Current index:", currentIndex);
      setCurrentIndex((prevIndex) =>
        prevIndex === predefinedqueries.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => {
      console.log("Clearing interval.");
      clearInterval(interval); 
    };
  }, [isInputActive, currentIndex]); // Dependency on isInputActive and currentIndex

  return (
    <TypingAnimation
      duration={60} // Adjust as needed
      delay={0}
      startOnView={false}
      className="my-custom-classes"
    >
      {predefinedqueries[currentIndex]}
    </TypingAnimation>
  );
}
