import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const sections = [
  {
    id: "about",
    title: "About",
    content: `At Mindlr, we're reinventing how people discover and use AI tools.
    We entered a world where AI capabilities are expanding daily, finding the right tool shouldn't be a barrier for adaptation. We're building the bridge between human potential and AI capabilities, making the journey from challenge to solution seamless and intuitive.

    Our platform doesn't just list AI tools – it understands your context. Through our unique blueprint system, we guide you step-by-step in integrating AI into your workflow, turning complex processes into clear, actionable paths. Whether you're a designer crafting concepts, a developer streamlining code, or a business professional optimizing operations, Mindlr helps you find and apply the perfect AI tools for your specific needs.`,
  },
  {
    id: "vision",
    title: "Our Vision",
    content: `We're working towards a future where AI integration is effortless and intuitive. Our roadmap is driven by three core beliefs:
    - Every person should be able to upskill themselves with AI, and a confusion of clusters of tools shouldn't stop anyone.
    - The best solutions come from combining human expertise with machine capabilities.
    - Community-driven insights are key to successful AI adoption.`,
  },
  {
    id: "whatsnext",
    title: "What's Next?",
    content: `Our journey is just beginning. We're working on:
    - Advanced recommendation systems that learn from user success patterns.
    - Expanded blueprint capabilities for complex workflows across industries.
    - Enhanced community features for sharing and collaborating on AI solutions.
    - Integrations within AI tools and platforms using API calls.`,
  },
  {
    id: "commitment",
    title: "Our Commitment",
    content: `As AI continues to evolve, we remain committed to being a trusted navigator in the AI landscape. We constantly update our platform, adding new tools, and refining our blueprints to ensure you always have access to the most effective AI solutions for your needs.

    We envision a future where AI tools enhance human creativity and productivity without the current friction of discovery and integration. By connecting people with the right AI solutions at the right time, we're making that future a reality, one blueprint at a time.`,
  },
];

function scrollWithOffset(element) {
  const yOffset = -140;
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}

export default function AboutPage() {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("about");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if (location.state?.section) {
      const targetId = location.state.section;
      const element = document.getElementById(targetId);
      if (element) {
        setTimeout(() => {
          scrollWithOffset(element);
          setActiveSection(targetId);
        }, 300);
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      const visibleSection = sections.find((section) => {
        const element = document.getElementById(section.id);
        if (!element) return false;
        const top = element.offsetTop;
        const bottom = element.offsetTop + element.offsetHeight;
        return scrollPosition >= top && scrollPosition < bottom;
      });

      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const target = document.getElementById(id);
    if (target) {
      scrollWithOffset(target);
      setActiveSection(id);
    }
  };

  return (
    <div className="bg-white font-montserrat">
      <header
        className="bg-[#2197bd] text-white py-4 px-6 flex items-center justify-between sticky top-0 z-20"
        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
      >
        <Link to="/" className="flex items-center">
          <img
            src="/images/mindrllogo.png"
            alt="Mindlr Logo"
            className="h-8 mr-2"
          />
          Mindlr
        </Link>

        <Link
          to="/sign-in"
          className="bg-black mr-0 md:mr-16 text-white font-bold flex items-center justify-center px-2 py-1 rounded-full border-2 border-white shadow-md hover:bg-gray-800 transition"
          style={{ fontSize: "1rem" }}
        >
          <span className="mr-2">Sign In</span>
          <div
            className="flex items-center justify-center bg-white text-black rounded-full"
            style={{ width: "24px", height: "24px" }}
          >
            <FaUser size={16} />
          </div>
        </Link>
      </header>

      <div className="flex">
        {/* Sidebar - Hidden on Mobile */}
        <nav className="hidden md:block w-1/6 sticky top-[4rem] bg-white p-6 text-black z-10 h-[calc(100vh-4rem)]">
          <h2
            className={`cursor-pointer hover:underline text-2xl  md:mt-20 mb-4 ${
              activeSection === "about" ? "" : ""
            }`}
            onClick={() => scrollToSection("about")}
          >
            About
          </h2>
          <ul className="space-y-4">
            {sections
              .filter((section) => section.id !== "about")
              .map((section) => (
                <li key={section.id}>
                  <button
                    onClick={() => scrollToSection(section.id)}
                    className={`${
                      activeSection === section.id ? "font-bold text-black" : ""
                    } hover:underline text-left`}
                  >
                    {section.title}
                  </button>
                </li>
              ))}
          </ul>
        </nav>

        {/* Divider */}
        <div className="hidden md:block w-[4px] bg-black"></div>

        {/* Main Content */}
        <div className="w-full md:w-3/4 px-8 py-6 mt-20 bg-white text-black">
          {sections.map((section, index) => (
            <section
              key={section.id}
              id={section.id}
              className={`mb-12 ${
                index === sections.length - 1 ? "pb-96" : ""
              }`}
            >
              <h2 className="text-xl mb-2">{section.title}</h2>
              <hr className="border-t-4 border-gray-800 mb-4 w-[98%]" />
              <p className="text-gray-800 font-poppins whitespace-pre-line">
                {section.content.replace(/^- /gm, "• ")}
              </p>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}
