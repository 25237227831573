import React, { useState, useEffect } from "react";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FiUserPlus, FiCheck } from "react-icons/fi"; // Follow and tick icons
import { useNavigate, Link } from "react-router-dom";

export default function People() {
  const [users, setUsers] = useState([]); // List of users
  const [loggedInUser, setLoggedInUser] = useState(null); // Logged-in user info
  const [following, setFollowing] = useState(new Set()); // Track followed users
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  // Fetch logged-in user info and initialize "following"
  useEffect(() => {
    const fetchLoggedInUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: "GET",
          credentials: "include",
        });

        if (response.ok) {
          const userData = await response.json();
          setLoggedInUser(userData);

          // Initialize following state from the fetched user data
          const followingSet = new Set(userData.following.map((u) => u._id));
          setFollowing(followingSet);
        } else {
          console.error("Failed to fetch logged-in user info");
        }
      } catch (error) {
        console.error("Error fetching logged-in user info:", error);
      }
    };
    fetchLoggedInUser();
  }, []);

  // Fetch all users based on query
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/people?query=${encodeURIComponent(
            query
          )}`,
          { method: "GET", credentials: "include" }
        );
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (loggedInUser) fetchUsers();
  }, [query, loggedInUser]);

  // Follow a user
  const handleFollow = async (uploaderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/follow/${uploaderId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: loggedInUser._id }),
          credentials: "include",
        }
      );

      if (response.ok) {
        setFollowing((prev) => new Set([...prev, uploaderId])); // Add user to the following set
      } else {
        console.error("Failed to follow user");
      }
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const handleProfileNavigation = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  const handleSearch = () => {
    navigate(`/home/people?query=${encodeURIComponent(query)}`);
  };

  const handleClearVideos = async () => {
    try {
      // API call to remove references
      const response = await fetch(
        "http://localhost:5001/api/videos/remove-references",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to clear video references");
      }

      const data = await response.json();
      console.log(data.message || "All video references cleared!");
      // You can also display a success message using toast or alert
      alert(data.message || "All video references cleared!");
    } catch (error) {
      console.error("Error clearing video references:", error);
      alert("Failed to clear video references. Please try again.");
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {" "}
      <button
        className="bg-black text-white px-4 py-2 rounded-md hover:bg-gray-700 transition"
        onClick={handleClearVideos}
      >
        CLEAR ALL VIDEOS
      </button>
      {/* Top Bar */}
      <div className="hidden md:flex items-center justify-between bg-gray-500 p-4 shadow-md">
        <div className="flex items-center justify-center w-full md:w-3/4 mx-auto md:ml-64">
          <div className="relative flex items-center">
            <img
              src="/images/mindrllogo.png"
              alt="Mindlr Logo"
              className="h-10 w-10 mx-2 hidden md:block"
              style={{ marginBottom: "0.5rem" }}
            />
          </div>
          <div className="relative w-full max-w-3xl h-12">
            <BsStars className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-500 text-2xl md:text-3xl" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search for people"
              className="h-full pl-16 bg-white w-full border border-gray-300 rounded-xl text-gray-800 pr-12 text-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
            />
            <CiSearch
              onClick={handleSearch}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500 text-3xl md:text-4xl"
            />
          </div>
        </div>
      </div>
      <div className="flex">
        {/* Left Sidebar */}
        <div className="hidden md:flex bg-gray-200 shadow-md w-1/5 rounded-lg p-4 flex-col items-center justify-start">
          <div className="flex flex-col items-center mt-4">
            <img
              src={loggedInUser?.profilePic || "/images/defaultprofpic1.jpg"}
              alt="Profile"
              className="rounded-full w-24 h-24 mb-4 border border-gray-300"
            />
            <h3 className="text-md font-poppins font-light text-gray-800">
              {loggedInUser?.name}
            </h3>
            <p className="text-sm text-gray-500">
              {loggedInUser?.bio || "No bio available"}
            </p>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 p-6">
          <div className="bg-slate-200 rounded-lg">
            {users.map((user, index) => (
              <div
                key={user._id}
                className={`flex items-center justify-between p-4 ${
                  index !== users.length - 1 ? "border-b border-gray-300" : ""
                }`}
              >
                <div className="flex items-center">
                  <img
                    src={user.profilePic || "/images/defaultprofpic1.jpg"}
                    alt="Profile"
                    className="rounded-full w-14 h-14 border border-gray-300 cursor-pointer"
                    onClick={() => handleProfileNavigation(user._id)}
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-poppins font-light text-gray-800">
                      {user.name}
                    </h3>
                    <p className="text-sm text-gray-500">{user.bio}</p>

                    {/* Tools Section */}
                    <div className="flex flex-wrap mt-2">
                      {user.toolbox.map((tool, index) => (
                        <a
                          key={index}
                          href={tool.tool?.Link || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="w-8 h-8 m-1"
                        >
                          <img
                            src={tool.tool?.Image || "/images/defaulttool.png"}
                            alt={tool.tool?.Name || "Tool"}
                            className="w-full h-full rounded-full border border-gray-300"
                          />
                        </a>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Follow Button */}
                <div className="mr-4">
                  {following.has(user._id) ? (
                    <FiCheck className="text-green-500 text-2xl" />
                  ) : (
                    <FiUserPlus
                      className="text-gray-500 text-2xl cursor-pointer hover:text-gray-400"
                      onClick={() => handleFollow(user._id)}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
