import React, { useEffect, useRef, useState } from "react";
import InstagramAuth from "../components/InstagramAuth";
import RedirectHandler from "../components/RedirectHandler";
import VideoCard from "../components/VideoCard";
import VideoUploadPopup from "../components/VideoUploadPopup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaPlus, FaPen, FaCheck, FaTrash } from "react-icons/fa";
import NotificationPopup from "../components/NotificationPopup"; // Ensure you import the NotificationPopup component
import FeedbackForm from "../components/FeedbackForm";
import WelcomePopup from "../components/WelcomePopup";
import SidebarPopup from "../components/SidebarPopup";
import ContentLoader from "react-content-loader";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FiLoader } from "react-icons/fi";
import TopBar from "../components/TopBar"; // Adjust the path if necessary
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../features/user/userSlice";
import { fetchUserVideos } from "../features/videos/videoSlice";
import { logoutUser } from "../features/user/userSlice";

const Profile = () => {
  //Redux implemented here :
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (userStatus === "idle") {
      // Means we haven't fetched yet
      dispatch(fetchUserData());
    }
  }, [userStatus, dispatch]);

  if (userStatus === "loading") {
    //console.log("loading");
  }

  // If there's an error
  if (userStatus === "failed") {
    //console.log("Error loading user data");
  }

  // If user is not logged in, maybe userData is null
  if (!userData) {
    //console.log("No user data found (not logged in?)");
  }

  const videos = useSelector((state) => state.videos.videos);
  const videoStatus = useSelector((state) => state.videos.status);
  const videoError = useSelector((state) => state.videos.error);

  useEffect(() => {
    if (userData && videoStatus === "idle") {
      dispatch(fetchUserVideos(userData?._id));
    }
  }, [dispatch, userData, videoStatus]);

  if (videoStatus === "loading") {
    //console.log("Loading videos");
  }

  if (videoStatus === "failed") {
    //console.log(`Error loading videos: ${videoError}`);
  }

  const [footerSettingsOpen, setFooterSettingsOpen] = useState(false);
  const footerSettingsRef = useRef(null);

  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("namekit");
  const [showRedirectHandler, setShowRedirectHandler] = useState(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  //const [videos, setVideos] = useState([]);
  const [aiTools, setAiTools] = useState([]);
  const [selectedTools, setSelectedTools] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [comments, setComments] = useState({}); // State for individual comments
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [newName, setNewName] = useState("");
  const [newBio, setNewBio] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0); // State for notification count
  const [showNotificationPopup, setShowNotificationPopup] = useState(false); // State for showing notification popup
  const [activeSection, setActiveSection] = useState(null); // Tracks the active section: "followers" or "following"
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const profilePicInputRef = useRef(null);
  const navigate = useNavigate();
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState(null); // Track currently playing video
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [popup1Shown, setPopup1Shown] = useState(false);
  const [popup2Shown, setPopup2Shown] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [searchUsersQuery, setSearchUsersQuery] = useState(""); // Search query state
  const [searchUsersNameSuggestions, setSearchUsersNameSuggestions] = useState(
    []
  ); // Store suggestions
  const [searchUsersShowDropdown, setSearchUsersShowDropdown] = useState(false); // Show/hide dropdown
  const [searchUsersLoading, setSearchUsersLoading] = useState(false); // Loading state

  // Fetch name suggestions when query changes
  const fetchSearchUsersNameSuggestions = async (query) => {
    if (!query.trim()) {
      setSearchUsersNameSuggestions([]); // Clear suggestions if query is empty
      setSearchUsersShowDropdown(false);
      return;
    }

    setSearchUsersLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/people/suggestions?query=${encodeURIComponent(query)}`, // Backend endpoint for suggestions
        {
          method: "GET",
          credentials: "include", // Include cookies/session
        }
      );

      if (response.ok) {
        const data = await response.json();
        //console.log(data);
        setSearchUsersNameSuggestions(data); // Update suggestions
        setSearchUsersShowDropdown(true); // Show dropdown
      } else {
        //console.error("Failed to fetch name suggestions");
      }
    } catch (error) {
      //console.error("Error fetching name suggestions:", error);
    } finally {
      setSearchUsersLoading(false);
    }
  };

  // Handle typing in the search bar
  const handleSearchUsersInput = (e) => {
    const value = e.target.value;
    setSearchUsersQuery(value); // Update query state
    fetchSearchUsersNameSuggestions(value); // Fetch suggestions
  };

  // Handle click on a suggestion
  const handleSearchUsersSuggestionClick = (suggestion) => {
    //console.log("User clicked on:", suggestion);
    // Optionally, navigate to a user's profile or handle another action
  };

  const handleVideoPlay = (videoRef) => {
    // Pause the previously playing video
    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoRef) {
      currentlyPlayingVideo.pause();
    }
    // Set the new currently playing video
    setCurrentlyPlayingVideo(videoRef);
  };

  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/count`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
        ////console.log(notificationCount);
      } else {
        //console.error("Failed to fetch notification count");
      }
    } catch (error) {
      //console.error("Error fetching notification count:", error);
    }
  };
  useEffect(() => {
    const handleFooterClickOutside = (event) => {
      if (
        footerSettingsRef.current &&
        !footerSettingsRef.current.contains(event.target)
      ) {
        setFooterSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleFooterClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleFooterClickOutside);
    };
  }, []);

  /*const fetchUserInfo = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        setNewName(userData?.name || "");
        setNewBio(userData?.bio || "");
        ////console.log("Fetched user data:", userData);
        fetchUserVideos(userData?._id);
        fetchNotificationCount(userData?._id);
        setLoading(false); // Set loading to false after data is fetched
      } else {
        //console.error("Failed to fetch user info");
        setError("Failed to fetch user info");
        setLoading(false); // Set loading to false in case of error
      }
    } catch (error) {
      //console.error("Error fetching user info:", error);
      setError("Error fetching user info");
      setLoading(false);
    }
  };*/

  // Call the fetchUserInfo function only once when the component mounts
  /*useEffect(() => {
    fetchUserInfo(); // Fetch the user data when the component loads

    // Optional cleanup function (if needed to cancel requests or handle unmounting)
    return () => {
      ////console.log("Cleaning up user profile fetch");
    };
  }, []);*/

  /*useEffect(() => {
    const queryParams = new URLSearchParams(location.search); // Use location.search to get current URL query
    const code = queryParams.get("code");
    const caseParam = queryParams.get("case"); // Fetch the 'case' query param

    if (code) {
      // If there is a code, switch to the "posts" tab and show the RedirectHandler
      setActiveTab("posts");
      setShowRedirectHandler(true);
    } else if (caseParam === "posts") {
      // If 'case=posts', activate the "posts" tab
      setActiveTab("posts");
    } else {
      setActiveTab("namekit"); // Default to namekit
    }
  }, [location.search]);*/

  const handleNotificationClick = async () => {
    try {
      // Mark notifications as read
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/mark-read`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userData._id }),
        }
      );

      if (response.ok) {
        setNotificationCount(0); // Reset notification count to 0
      } else {
        //console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      //console.error("Error marking notifications as read:", error);
    }

    setShowNotificationPopup((prev) => !prev); // Toggle the notification popup
  };

  const handleProfilePicUpload = async (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/profile-pic`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        ////console.log("Updated User:", updatedUser);
        dispatch(fetchUserData());
      } else {
        //console.error("Failed to upload profile picture");
      }
    } catch (error) {
      //console.error("Error uploading profile picture:", error);
    }
  };

  /*const fetchUserVideos = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/videos?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        const videoData = await response.json();
        setVideos(videoData);
      } else {
        //console.error("Failed to fetch videos:", response.statusText);
      }
    } catch (error) {
      //console.error("Error fetching videos:", error);
    }
  };*/

  const handleToolSelect = async (toolId) => {
    try {
      ////console.log("Adding Tool:", toolId);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/tools/add`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ tools: [toolId] }),
        }
      );

      if (response.ok) {
        //alert("Tool added successfully!");
        setShowDropdown(false);
        dispatch(fetchUserData()); // No reload needed
      } else {
        //console.error("Failed to add tool:", response.statusText);
      }
    } catch (error) {
      //console.error("Error adding tool:", error);
      alert("An error occurred while adding the tool.");
    }
  };

  const handleSearch = async (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    setSearching(true);

    if (term.trim() === "") {
      setSearchResults([]);
      setSearching(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai-tools/search?term=${term}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const results = await response.json();
        setSearchResults(results);
      } else {
        //console.error("Failed to search AI tools");
      }
    } catch (error) {
      //console.error("Error searching AI tools:", error);
    } finally {
      setSearching(false);
    }
  };

  const handleCommentChange = (e, toolId) => {
    const newComment = e.target.value;

    if (!toolId) {
      //console.error("Tool ID is undefined or null in handleCommentChange");
      return;
    }

    // Check if userData exists and has a toolbox
    if (!userData || !userData?.toolbox) {
      //console.error("User data or toolbox is not available");
      return;
    }

    // Dispatch updated comments directly
    setComments((prevComments) => ({
      ...prevComments,
      [toolId]: newComment,
    }));

    // Optionally, log the updated state
    //console.log(
    //`Updated comment for Tool ID: ${toolId}, New Comment: ${newComment}`
    //);
  };

  const handleCommentSubmit = async (toolId) => {
    ////console.log("Attempting to submit comment for Tool ID:", toolId);

    // Call the handleSaveAllComments function to save all comments
    handleSaveAllComments();
  };

  const saveComment = async (toolId, comment) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/tools/comments`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ toolId, comment }),
        }
      );

      if (!response.ok) {
        //console.error("Failed to save comment:", response.statusText);
      } else {
        ////console.log("Comment saved successfully for tool ID", toolId);
      }
    } catch (error) {
      //console.error("Error saving comment:", error);
    }
  };

  const handleUploadComplete = () => {
    setShowRedirectHandler(false);
    if (user) fetchUserVideos(user._id);
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };
  const toggleSection = (section) => {
    if (activeSection === section) {
      // Close the section if it's already open
      setActiveSection(null);
      setUserList([]);
    } else {
      // Open the clicked section
      setActiveSection(section);
      setUserList(
        section === "followers" ? userData?.followers : userData?.following
      );
    }
  };
  const navigateToUploaderProfile = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`); // Example route, adjust to your routing
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/logout`,
        {
          method: "POST",
          credentials: "include",
        }
      );
      if (response.ok) {
        // Reset Redux state
        dispatch(logoutUser());

        // Navigate to the sign-in page
        navigate("/sign-in");
      } else {
        //console.error("Failed to log out");
      }
    } catch (error) {
      //console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // This is important to send the cookies with the request
          body: JSON.stringify({
            userId: userData._id, // Send the user ID in the request body
            currentPassword,
            newPassword,
          }),
        }
      );

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        // Try to parse the response as JSON
        try {
          const data = await response.json();
          alert(`Failed to update password: ${data.message}`);
        } catch (jsonError) {
          // If parsing fails, display a general error message
          alert("Failed to update password: An error occurred.");
        }
      }
    } catch (error) {
      //console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/update/${userData._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ name: newName, bio: newBio }),
        }
      );

      if (response.ok) {
        alert("Profile updated successfully!");
        setShowEditPopup(false);
        window.location.reload(); // Refresh the page to show updated info
      } else {
        //console.error("Failed to update profile:", response.statusText);
      }
    } catch (error) {
      //console.error("Error updating profile:", error);
      alert("An error occurred while updating the profile.");
    }
  };

  const handleSaveAllComments = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/tools/comments`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ comments }),
        }
      );

      if (!response.ok) {
        //console.error("Failed to save comments:", response.statusText);
        alert("Failed to save comments. Please try again.");
      } else {
        // Refresh user data from the server and update Redux
        await dispatch(fetchUserData());
        setComments({}); // Clear the comments state
        alert("Comments saved successfully!");
      }
    } catch (error) {
      //console.error("Error saving comments:", error);
    }
  };

  const handleToolDelete = async (toolId) => {
    if (!userData || !userData?._id) {
      //console.error("User data is not available");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${userData?._id}/toolbox/${toolId}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.ok) {
        // Refresh user data from the server and update Redux
        await dispatch(fetchUserData());
        alert("Tool deleted successfully");
      } else {
        //console.error("Failed to delete tool:", response.statusText);
      }
    } catch (error) {
      //console.error("Error deleting tool:", error);
    }
  };

  const [textareaHeights, setTextareaHeights] = useState({});
  const textareaRefs = useRef({}); // Ref to hold references to all textareas

  // Function to handle dynamic resizing of textareas
  const handleResize = (toolId, event) => {
    const textarea = event.target;
    textarea.style.height = "auto"; // Reset height to auto
    textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to fit content

    // Store the height in state
    setTextareaHeights((prevHeights) => ({
      ...prevHeights,
      [toolId]: textarea.scrollHeight,
    }));
  };

  // Ensure the textareas are resized correctly when the component mounts or the content updates
  useEffect(() => {
    Object.keys(textareaRefs.current).forEach((toolId) => {
      const textarea = textareaRefs.current[toolId];
      if (textarea) {
        textarea.style.height = "auto"; // Reset height
        textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height based on content
      }
    });
  }, [comments]); // Re-run the effect whenever the `comments` state changes

  const dropdownRef = useRef(null);
  const mobileDropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    // Check if the click is outside both the desktop and mobile dropdowns
    const clickedOutsideDesktop =
      dropdownRef.current && !dropdownRef.current.contains(event.target);
    const clickedOutsideMobile =
      mobileDropdownRef.current &&
      !mobileDropdownRef.current.contains(event.target);

    if (clickedOutsideDesktop && clickedOutsideMobile) {
      setSettingsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderContent = () => {
    switch (activeTab) {
      case "posts":
        return (
          <div>
            {/*<div className="md:ml-6 w-screen md:w-auto flex justify-center items-center">
              <div className="flex items-center space-x-0 mr-40 mt-4 md:mt-4 md:mr-0 ml-4 md:ml-10">
                <InstagramAuth onClick={() => setShowRedirectHandler(true)} />
                {showRedirectHandler && (
                  <RedirectHandler onUploadComplete={handleUploadComplete} />
                )}
                <button
                  onClick={() => setShowUploadPopup(true)}
                  className="text-black font-poppins bg-slate-500 rounded-full p-4"
                >
                  <FaPlus className="text-2xl" />
                </button>
                {showUploadPopup && (
                  <VideoUploadPopup
                    onClose={() => setShowUploadPopup(false)}
                    onUploadComplete={handleUploadComplete}
                    userId={user._id} 
                  />
                )}
              </div>
            </div>*/}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-1 mt-8">
              {videos.map((video, index) => (
                <div id={video._id} key={video._id}>
                  <VideoCard
                    video={video}
                    index={index}
                    handleVideoPlay={handleVideoPlay} // Pass the handler to VideoCard
                    className="h-36"
                  />
                </div>
              ))}
            </div>
          </div>
        );

      case "namekit":
        return (
          <div className="ml-4 mt-8 md:mt-10 rounded-3xl md:ml-0 mb-20 md:md-0 bg-tealcanva10 px-4">
            <div className="space-y-8 mt-4">
              {userData?.toolbox && userData?.toolbox.length > 0 ? (
                userData?.toolbox.map((toolItem, index) => {
                  const toolId = toolItem._id.toString();

                  return (
                    <React.Fragment key={toolId}>
                      <div className="flex items-center space-x-2 w-full md:w-auto">
                        <div className="flex flex-col items-center w-20 md:w-48 space-y-1">
                          <a
                            href={toolItem.tool.Link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={toolItem.tool.Image} // Directly using the image URL from MongoDB
                              alt={toolItem.tool.Name}
                              className="w-12 h-12 mt-6 rounded-full cursor-pointer" // Added cursor-pointer for hover effect
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/defaultprofpic1.jpg";
                              }}
                            />
                          </a>
                          <span className="mt-2 text-black">
                            {toolItem.tool.Name}
                          </span>
                        </div>

                        <div className="relative flex w-full mt-4 md:mt-0">
                          <textarea
                            value={
                              comments[toolId] !== undefined
                                ? comments[toolId]
                                : toolItem.comment || ""
                            }
                            onChange={(e) => handleCommentChange(e, toolId)}
                            placeholder={`Why ${toolItem.tool.Name}?`}
                            className="text-black font-open-sans border-none p-2 md:p-4 border bg-graycanva1 rounded-lg md:rounded-full ml-0 md:ml-0 md:mt-4 w-full pr-20 text-start  "
                            style={{
                              wordBreak: "break-word", // Ensures long words are wrapped correctly
                              lineHeight: "1.2", // Line height for spacing
                              boxSizing: "border-box", // Ensures padding is part of width
                              maxWidth: "100%", // Ensures textarea doesn't overflow its container
                              minWidth: "0", // Prevents shrinking too much
                              padding: "20px", // Padding added for better text spacing
                              paddingRight: "62px", // Space for the FaCheck icon
                              textAlign: "justify", // Even text distribution
                              resize: "none", // Disables manual resizing
                              height: "auto", // Adjusts height automatically
                            }}
                          />
                          {comments[toolId] && (
                            <FaCheck
                              className="absolute top-1/2 text-black transform -translate-y-1/2 right-4 cursor-pointer"
                              onClick={() => handleCommentSubmit(toolId)}
                            />
                          )}
                        </div>
                        <style jsx>{`
                          @media (max-width: 768px) {
                            textarea {
                              min-height: 150px; // Larger default height for mobile screens
                              padding: 30px; // Adds more padding for mobile view
                              padding-right: 60px; // Maintain space for FaCheck on mobile too
                            }
                          }
                        `}</style>

                        {/* Delete Tool Icon */}
                        <FaTrash
                          className="text-red-500 mt-4 cursor-pointer ml-4 text-lg"
                          style={{ marginRight: "8px" }}
                          onClick={() => setShowDeletePopup(toolId)}
                        />
                      </div>
                      <hr className="my-2 md:ml-56 ml-24 mr-20 border-1 border-black " />

                      {/* Delete Confirmation Popup */}
                      {showDeletePopup === toolId && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                            <h2 className="text-xl font-bold text-black mb-4">
                              Are you sure?
                            </h2>
                            <div className="flex justify-around">
                              <button
                                onClick={() => setShowDeletePopup(null)}
                                className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                              >
                                No
                              </button>
                              <button
                                onClick={() => handleToolDelete(toolId)}
                                className="bg-red-500 text-black px-4 py-2 rounded-full"
                              >
                                Yes, Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                // React Content Loader for Placeholder
                <div className="flex items-center space-x-0 w-full md:w-auto">
                  <div className="flex flex-col items-center w-20 md:w-48 space-y-1">
                    <ContentLoader
                      speed={2}
                      width={60}
                      height={60}
                      viewBox="0 0 60 60"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <circle cx="30" cy="30" r="30" />
                    </ContentLoader>
                    <ContentLoader
                      speed={2}
                      width={80}
                      height={20}
                      viewBox="0 0 80 20"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="0" y="0" rx="3" ry="3" width="80" height="20" />
                    </ContentLoader>
                  </div>

                  <div className="relative flex w-full">
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={50}
                      viewBox="0 0 400 50"
                      backgroundColor="#f3f3f3"
                      foregroundColor="#ecebeb"
                    >
                      <rect x="0" y="0" rx="5" ry="5" width="400" height="50" />
                    </ContentLoader>
                  </div>
                </div>
              )}
            </div>
            {/* Add Tool Button */}
            <div className="flex justify-start ml-12 mt-4">
              <div className="relative mr-4">
                <button
                  onClick={() => setShowDropdown((prev) => !prev)}
                  className="bg-tealcanva2 text-white p-4 mb-3 rounded-full"
                >
                  <FaPlus className="text-sm text-black" />
                </button>
                {showDropdown && (
                  <div
                    className="bg-white shadow-lg rounded-lg p-4 mt-4 w-full max-w-[720px]"
                    style={{
                      minHeight: "8rem",
                      maxHeight: "8rem",
                      overflowY: "auto",
                    }} // Fixed height with scrollable content
                  >
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder="Search tools..."
                      className="mb-4 px-2 py-1 border border-gray-300 rounded w-full"
                    />
                    {searching ? (
                      <p>Searching...</p>
                    ) : searchResults.length > 0 ? (
                      <div className="space-y-2">
                        {" "}
                        {/* Added spacing between items */}
                        {searchResults.map((tool) => (
                          <div
                            key={tool._id}
                            className="flex items-center cursor-pointer"
                            onClick={() => handleToolSelect(tool._id)}
                          >
                            <img
                              src={tool.Image}
                              alt={tool.Name}
                              className="w-10 h-10 mr-2"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/defaultprofpic1.jpg";
                              }}
                            />
                            <span className="text-black">{tool.Name}</span>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="space-y-2">
                        {" "}
                        {/* Added spacing for fallback content */}
                        {aiTools.map((tool) => (
                          <div
                            key={tool._id}
                            className="flex items-center cursor-pointer"
                            onClick={() => handleToolSelect(tool._id)}
                          >
                            <img
                              src={tool.Image}
                              alt={tool.Name}
                              className="w-10 h-10 mr-2"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "/images/defaultprofpic1.jpg";
                              }}
                            />
                            <span className="text-black">{tool.Name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );

      case "savedPosts":
        return (
          <div className="ml-4 mt-10 rounded-3xl md:ml-6 mb-20 md:md-0 bg-tealcanva10">
            <h2 className="text-lg font-bold text-center text-gray-700">
              Saved Posts
            </h2>
            <p className="text-center text-gray-500">
              No content yet. Saved posts will appear here.
            </p>
          </div>
        );

      case "interactions":
        return (
          <div className="ml-4 md:ml-6">
            {/* Interactions content goes here */}
          </div>
        );
      default:
        return null;
    }
  };
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const [loadingUserData, setLoadingUserData] = useState(true);

  useEffect(() => {
    const fetchUserPopupStatus = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const userData = await response.json();
          setDisclaimerAccepted(userData?.disclaimerAccepted || false);
          setPopup1Shown(userData?.popup1Shown || false);
          setPopup2Shown(userData?.popup2Shown || false);
        } else {
          //console.error("Failed to fetch user data");
        }
      } catch (error) {
        //console.error("Error fetching user data:", error);
      } finally {
        setLoadingUserData(false); // Mark loading as complete
      }
    };

    fetchUserPopupStatus();
  }, []);

  const updatePopupStatus = async (popupType) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-popup-status`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: user._id, popupType }),
        }
      );

      if (response.ok) {
        if (popupType === "disclaimerAccepted") setDisclaimerAccepted(true);
        if (popupType === "popup1Shown") setPopup1Shown(true);
        if (popupType === "popup2Shown") setPopup2Shown(true);
      } else {
        //console.error(`Failed to update ${popupType}`);
      }
    } catch (error) {
      //console.error(`Error updating ${popupType}:`, error);
    }
  };

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const notificationDropdownRef = useRef(null);

  const toggleNotificationDropdown = () => {
    setIsNotificationOpen((prev) => !prev);
  };

  const closeNotificationDropdown = (event) => {
    if (
      notificationDropdownRef.current &&
      !notificationDropdownRef.current.contains(event.target)
    ) {
      setIsNotificationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeNotificationDropdown);
    return () => {
      document.removeEventListener("mousedown", closeNotificationDropdown);
    };
  }, []);

  const [query, setQuery] = useState("");
  const [searchUsersDropdown, setSearchUsersDropdown] = useState(false);
  const [searchUsersSuggestions, setSearchUsersSuggestions] = useState([]);

  // Fetch live suggestions
  const fetchSuggestions = async (query) => {
    if (!query.trim()) {
      setSearchUsersSuggestions([]);
      setSearchUsersDropdown(false);
      return;
    }

    //setLoading(true);
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/people/suggestions?query=${encodeURIComponent(query)}`,
        { method: "GET", credentials: "include" }
      );
      if (response.ok) {
        const data = await response.json();
        setSearchUsersSuggestions(data);
        setSearchUsersDropdown(true);
      } else {
        //console.error("Failed to fetch suggestions");
      }
    } catch (error) {
      //console.error("Error fetching suggestions:", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle click on suggestion
  const handleSearchUsersClick = (suggestion) => {
    //console.log("Selected user:", suggestion);
    setSearchUsersDropdown(false);
  };
  // Profile.js (or wherever your Profile component is)

  useEffect(() => {
    if (query.trim()) {
      fetchSuggestions(query);
    } else {
      // If user cleared the search
      setSearchUsersDropdown(false);
      setSearchUsersSuggestions([]);
    }
  }, [query]); // <--- watch the query state

  // The rest of your code...

  return (
    <>
      {/* 1) Sticky container wraps BOTH the TopBar and the mobile header */}
      <div className="sticky top-0 z-50 bg-white">
        {/* TopBar (visible on all screen sizes) */}
        <TopBar
          searchEnabled={true}
          query={query}
          setQuery={setQuery}
          //loading={loading}
          handleSearchClicked={() => {}}
          handleKeyPress={(e) => {
            if (e.key === "Enter") {
              // ...
            }
          }}
          searchUsersDropdown={searchUsersDropdown}
          setSearchUsersDropdown={setSearchUsersDropdown}
          searchUsersSuggestions={searchUsersSuggestions}
          handleSearchUsersClick={handleSearchUsersClick}
          notificationCount={notificationCount}
          setNotificationCount={setNotificationCount}
          handleNotificationClick={handleNotificationClick}
          showNotificationPopup={showNotificationPopup}
          setShowNotificationPopup={setShowNotificationPopup}
          dropdownOpen={dropdownOpen}
          setDropdownOpen={setDropdownOpen}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          handleLogout={handleLogout}
          setShowResetPasswordPopup={setShowResetPasswordPopup}
          setShowLogoutPopup={setShowLogoutPopup}
          userId={userData?._id || null}
          showHomeIcon={true}
          currentPage="profile"
          navigateToHome={false}
          currentTab="toolkits"
        />

        {/* Mobile header (only shows on small screens) */}
        <div className="md:hidden bg-white w-full p-4 flex flex-col items-start">
          {/* Profile Picture */}
          <div className="relative rounded-full overflow-hidden h-24 w-24 bg-slate-500">
            <img
              src={userData?.profilePic || "/images/finalplaceholder.png"}
              alt="Profile"
              className="h-full w-full object-cover"
              onClick={() => profilePicInputRef.current?.click()}
            />
            <input
              type="file"
              id="profilePicInput"
              ref={profilePicInputRef}
              style={{ display: "none" }}
              onChange={handleProfilePicUpload}
            />
            <FaPen
              className="hidden absolute bottom-2 right-2 text-lg cursor-pointer text-black"
              onClick={handleEditClick}
            />
          </div>

          {/* Name and Bio */}
          <div className="mt-4 text-center">
            {!userData || !userData?.name ? (
              <div>
                <div className="bg-gray-200 h-6 w-3/4 rounded-md mx-auto animate-pulse mb-2"></div>
                <div className="bg-gray-200 h-4 w-2/3 rounded-md mx-auto animate-pulse"></div>
              </div>
            ) : (
              <div>
                <h2 className="text-xl font-poppins text-black">
                  {userData?.name}
                </h2>
                <p className="text-sm text-gray-700 font-poppins">
                  {userData?.bio || "No bio available."}
                </p>
              </div>
            )}
          </div>

          {/* Followers and Following */}
          <div className="flex justify-center space-x-4 mt-4">
            <div
              className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 text-center cursor-pointer"
              onClick={() => toggleSection("followers")}
            >
              <p className="text-black text-md">
                <span className="font-sans">
                  {userData?.followers?.length || 0}
                </span>{" "}
                Followers
              </p>
            </div>
            <div
              className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 text-center cursor-pointer"
              onClick={() => toggleSection("following")}
            >
              <p className="text-black text-md">
                <span className="font-sans">
                  {userData?.following?.length || 0}
                </span>{" "}
                Following
              </p>
            </div>
          </div>

          {/* Followers/Following List */}
          {activeSection && (
            <div className="mt-4 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto w-full">
              <h2 className="text-lg font-sans font-semibold text-black mb-4 text-center">
                {activeSection === "followers" ? "Followers" : "Following"}
              </h2>
              {userList.length > 0 ? (
                userList.map((viewer) => (
                  <div
                    key={viewer._id}
                    className="flex items-center space-x-4 cursor-pointer mb-2"
                    onClick={() => navigateToUploaderProfile(viewer._id)}
                  >
                    <img
                      src={viewer.profilePic || "/images/defaultprofpic1.jpg"}
                      alt={viewer.name}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                    <span className="text-black text-sm">{viewer.name}</span>
                  </div>
                ))
              ) : (
                <p className="text-black font-poppins text-sm text-center">
                  No users found.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="relative min-h-screen flex pt-0 md:pt-0 bg-white">
        {/* Sidebar for Desktop */}
        <div className="fixed bottom-4 mb-16 right-4 md:bottom-6 md:right-6 z-50">
          <FeedbackForm userId={userData?._id} />
        </div>
        <div className="w-[220px] bg-white hidden md:flex flex-col items-center justify-between p-4 ">
          {/* Profile Section */}
          <div className="w-full text-center">
            {/* Profile Picture */}
            <div className="relative mt-14 rounded-full overflow-hidden h-20 w-20 md:h-32 md:w-32 mx-auto bg-slate-500">
              <img
                src={userData?.profilePic || "/images/finalplaceholder.png"}
                alt="Profile"
                className="h-full w-full object-cover"
                onClick={() => profilePicInputRef.current?.click()}
              />
              <input
                type="file"
                id="profilePicInput"
                ref={profilePicInputRef}
                style={{ display: "none" }}
                onChange={handleProfilePicUpload}
              />
              <FaPen
                className="absolute bottom-2 right-2 text-lg cursor-pointer text-gray-600"
                onClick={handleEditClick}
              />
            </div>

            {/* Name and Bio */}
            <div className="">
              {!userData || !userData?.name ? (
                <div>
                  <div className="bg-gray-200 h-6 w-3/4 rounded-md mx-auto animate-pulse mb-2"></div>
                  <div className="bg-gray-200 h-4 w-2/3 rounded-md mx-auto animate-pulse"></div>
                </div>
              ) : (
                <div>
                  <h2 className="text-xl font-poppins text-black">
                    {userData?.name}
                  </h2>
                  <p className="text-sm text-gray-700 font-poppins">
                    {userData?.bio || "No bio available."}
                  </p>
                </div>
              )}
            </div>
          </div>

          {/* Followers and Following Section */}
          <div className="mt-0 mb-80  w-full">
            <div
              className="bg-tealcanva6 shadow-md rounded-full px-4 py-2 mb-4 text-center cursor-pointer"
              onClick={() => toggleSection("followers")}
            >
              <p className="text-black text-md">
                <span className="font-sans">
                  {userData?.followers?.length || 0}
                </span>{" "}
                Followers
              </p>
            </div>
            <div
              className="bg-tealcanva6 shadow-md rounded-full px-2 py-2 mb-4 text-center cursor-pointer"
              onClick={() => toggleSection("following")}
            >
              <p className="text-black text-md">
                <span className="font-sans">
                  {userData?.following?.length || 0}
                </span>{" "}
                Following
              </p>
            </div>
          </div>

          {/* Conditionally Render Followers/Following List */}
          {activeSection && (
            <div className="mt-4 bg-white rounded-lg shadow-md p-4 max-h-60 overflow-y-auto w-full">
              <h2 className="text-lg font-bold text-black mb-4 text-center">
                {activeSection === "followers" ? "Followers" : "Following"}
              </h2>
              {userList.length > 0 ? (
                userList.map((uploader) => (
                  <div
                    key={uploader._id}
                    className="flex items-center space-x-4 cursor-pointer mb-2"
                    onClick={() => navigateToUploaderProfile(uploader._id)}
                  >
                    <img
                      src={uploader.profilePic || "/images/defaultprofpic1.jpg"}
                      alt={uploader.name}
                      className="w-8 h-8 rounded-full object-cover"
                    />
                    <span className="text-black text-sm">{uploader.name}</span>
                  </div>
                ))
              ) : (
                <p className="text-black font-poppins text-sm text-center">
                  No users found.
                </p>
              )}
            </div>
          )}
        </div>

        {/* Main Content */}
        <div
          className={`pt-0 flex-1 pr-0 md:pr-4 mt-10 p-0 py-2 md:py-0 md:p-20 bg-white rounded-3xl mr-4 w-full md:mr-0`}
        >
          {/* Tabs Section */}
          <div className="mt-4 flex justify-between border-b">
            <button
              className={`flex-1 px-1 py-2 font-poppins md:text-xl text-black text-center ${
                activeTab === "namekit"
                  ? "rounded-full bg-tealcanva7 font-sans text-black"
                  : ""
              }`}
              onClick={() => setActiveTab("namekit")}
            >
              My Toolkit
            </button>
            <button
              className={`flex-1 px-1 py-2 font-poppins md:text-xl text-black text-center ${
                activeTab === "posts"
                  ? "rounded-full bg-tealcanva7 text-black font-sans"
                  : ""
              }`}
              onClick={() => setActiveTab("posts")}
            >
              Posts
            </button>
            <button
              className={`flex-1 px-1 py-2 font-poppins md:text-xl text-black text-center ${
                activeTab === "savedPosts"
                  ? "rounded-full bg-tealcanva7 text-black font-sans"
                  : ""
              }`}
              onClick={() => setActiveTab("savedPosts")}
            >
              Saved Posts
            </button>
            {/*<button
            className={`flex-1 px-1 py-2 font-poppins  md:text-xl text-black text-center ${
              activeTab === "interactions"
                ? "rounded-full bg-gray-500 text-white"
                : ""
            }`}
            onClick={() => setActiveTab("interactions")}
          >
            Interactions
          </button>*/}
          </div>

          {renderContent()}
        </div>
        <div className="hidden md:flex flex-col justify-start items-center bg-white p-4 rounded-lg w-60 h-auto mt-2 ml-4"></div>

        {/* Sticky footer for Mobile View */}
        <div className="fixed bottom-0 left-0 w-screen right-0 bg-tealcanva2 p-4 flex justify-between items-center md:hidden z-50">
          <Link to="/home" className="flex-1 text-center">
            <span
              className="material-icons text-black"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              home
            </span>
          </Link>

          <Link to="/profile" className="flex-1 text-center">
            <span
              className="material-icons text-black text-sm"
              style={{
                cursor: "pointer",
                fontSize: "32px",
              }}
            >
              person
            </span>
          </Link>

          <div className="relative flex-1 text-center">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={handleNotificationClick}
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span
                style={{
                  position: "absolute",
                  top: "-4px",
                  right: "20%",
                  background: "red",
                  color: "white",
                  borderRadius: "50%",
                  padding: "2px 6px",
                  fontSize: "14px",
                }}
              >
                {notificationCount}
              </span>
            )}
            {/* Conditionally render the NotificationPopup */}
            {showNotificationPopup && (
              <NotificationPopup
                userId={userData._id}
                onClose={() => setShowNotificationPopup(false)}
                position="up"
              />
            )}
          </div>

          <div className="relative flex-1 text-center">
            <span
              className="material-icons text-black cursor-pointer"
              style={{ fontSize: "32px" }}
              onClick={() => setFooterSettingsOpen((prev) => !prev)}
            >
              settings
            </span>

            {footerSettingsOpen && (
              <div
                ref={footerSettingsRef}
                className="absolute right-0 bottom-12 w-48 bg-white rounded-md shadow-lg z-50"
                onClick={(e) => e.stopPropagation()} // Prevent bubbling
              >
                <button
                  onClick={() => setShowResetPasswordPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => handleLogout()}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Logout Confirmation Popup */}
        {showLogoutPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-xl font-bold font-poppins text-black mb-4">
                Are you sure?
              </h2>
              <p className="mb-6">Do you want to log out?</p>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowLogoutPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  No
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-black px-4 py-2 rounded-full"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Reset Password Popup */}
        {showResetPasswordPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold text-black mb-4">
                Reset Password
              </h2>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-bold mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowResetPasswordPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  Cancel
                </button>
                <button
                  onClick={handleResetPassword}
                  className="bg-blue-500 text-black px-4 py-2 rounded-full"
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        )}
      </div>{" "}
    </>
  );
};

export default Profile;
