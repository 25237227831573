import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ShimmerButton from "../components/ShimmerButton";
import { NeonGradientCard } from "../components/NeonGradientCard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { fetchUserData } from "../features/user/userSlice";

export default function SignIn() {
  const [identifier, setIdentifier] = useState(""); // Identifier can be email or username
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordPopup, setForgotPasswordPopup] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = { identifier, password };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/authenticate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
          credentials: "include",
        }
      );

      if (response.status === 200) {
        await dispatch(fetchUserData()).unwrap();
        navigate("/home", { replace: true });
      } else if (response.status === 400) {
        setErrorMessage("Invalid credentials. Please try again.");
      } else if (response.status === 404) {
        setErrorMessage("User does not exist. Please check your credentials.");
      }
    } catch (error) {
      //console.error("Error signing in", error);
      setErrorMessage(
        "An error occurred while signing in. Please try again later."
      );
    }
  };

  const handleForgotPassword = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.status === 200) {
        toast.success("Password reset email sent successfully.");
        setForgotPasswordPopup(false);
      } else if (response.status === 404) {
        toast.error("User not found. Please check your email.");
      } else {
        toast.error("Error resetting password. Please try again.");
      }
    } catch (error) {
      //console.error("Error in forgot-password:", error);
      toast.error("Server error. Please try again later.");
    }
  };

  const handleGoogleSuccess = async (response) => {
    const token = response.credential;

    if (!token) {
      toast.error("Failed to get Google credential. Please try again.");
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/google-auth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
        credentials: "include", // Include cookies
      });

      if (res.status === 200) {
        toast.success("Signed in with Google successfully!");
        await dispatch(fetchUserData()).unwrap();
        navigate("/home");
      } else {
        const errorData = await res.json();
        toast.error(errorData.message || "Google sign-in failed.");
      }
    } catch (error) {
      toast.error("An error occurred while signing in with Google.");
    }
  };

  const handleGoogleFailure = (error) => {
    toast.error("Failed to sign in with Google. Please try again.");
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div className="min-h-screen bg-white flex justify-center p-4 md:p-0 items-center">
        <NeonGradientCard className="w-full max-w-md p-0 bg-tealcanva6">
          <form
            onSubmit={handleSubmit}
            className="px-6 py-2 md:py-8 bg-tealcanva6"
          >
            <h2 className="text-4xl font-poppins mb-4 text-center text-black">
              Sign In
            </h2>
            <div className="mb-4">
              <label
                htmlFor="identifier"
                className="block mb-2 font-poppins text-black"
              >
                Email/Username
              </label>
              <input
                type="text"
                id="identifier"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                className="w-full p-2 border border-gray-400 rounded-md bg-white text-black"
                autoComplete="username"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block mb-2 font-poppins text-black"
              >
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2 border border-gray-400 rounded-md bg-white text-black"
                  autoComplete="current-password"
                  required
                />
                <span
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <AiOutlineEyeInvisible size={20} />
                  ) : (
                    <AiOutlineEye size={20} />
                  )}
                </span>
              </div>
            </div>

            {errorMessage && (
              <p className="text-red-500 font-poppins mb-4 text-center">
                {errorMessage}
              </p>
            )}

            <div className="flex flex-col items-center mt-4">
              <ShimmerButton className="font-poppins mx-auto md:mx-0">
                Sign In
              </ShimmerButton>
              <div className="my-4 w-full flex justify-center">
                <GoogleLogin
                  onSuccess={handleGoogleSuccess}
                  onError={handleGoogleFailure}
                  text="signin_with"
                  theme="filled_blue"
                  shape="pill"
                />
              </div>
              <button
                type="button"
                className="mt-4 text-blue-500 hover:underline"
                onClick={() => setForgotPasswordPopup(true)}
              >
                Forgot Password?
              </button>
              <button
                type="button"
                className="mt-2 text-blue-500 underline hover:underline"
                onClick={() => navigate("/register")}
              >
                Register here!
              </button>
            </div>
          </form>
        </NeonGradientCard>

        {/* Forgot Password Popup */}
        {forgotPasswordPopup && (
          <div className="fixed inset-0 bg-white p-4 md:p-0 bg-opacity-40 flex justify-center items-center z-50">
            <div className="bg-tealcanva2 p-8 rounded-lg shadow-xl w-96 relative z-50">
              <h3 className="text-2xl font-semibold font-poppins mb-6 text-center text-gray-800">
                Reset Password
              </h3>
              <label
                htmlFor="email"
                className="block mb-2 ml-2 text-gray-800 font-semibold font-poppins"
              >
                Enter your email
              </label>
              <input
                id="email"
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-blue-400"
              />
              <div className="flex justify-start gap-4">
                <button
                  onClick={() => setForgotPasswordPopup(false)}
                  className="px-6 py-2 bg-gray-600 text-white font-poppins rounded-lg hover:bg-gray-700 transition-all"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleForgotPassword(email)}
                  className="px-6 py-2 bg-blue-500 text-white font-poppins rounded-lg hover:bg-blue-600 transition-all"
                >
                  Reset
                </button>
              </div>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </GoogleOAuthProvider>
  );
}
