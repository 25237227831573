// userSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// 1) The async thunk that fetches user data from /api/user
export const fetchUserData = createAsyncThunk(
  "user/fetchUserData", // the action type
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        method: "GET",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user data");
      }
      const userData = await response.json();
      return userData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  userData: null, // Will hold { name, bio, profilePic, toolbox, followers, following, ... }
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // if you want to do something synchronous, e.g. updateName, etc.
    logoutUser: (state) => {
      state.userData = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userData = action.payload; // store user data
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Something went wrong";
      });
  },
});

export const { logoutUser } = userSlice.actions;
export default userSlice.reducer;
