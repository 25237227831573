import React from "react";
import { FaLinkedin, FaTwitter, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-tealcanva1 text-gray-800 font-montserrat w-full">
      {/* Top Section */}
      <div className="py-4 px-6 md:px-16 lg:px-32">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl md:text-3xl font-bold">Mindlr Inc</h2>
          <div className="flex space-x-4">
            <a
              href="https://www.linkedin.com/company/mindlr/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 text-2xl hover:text-blue-700"
            >
              <FaLinkedin />
            </a>
            <a
              href="https://x.com/mindlr_ai?s=11&t=HS-khiQ99MWkaJ0JlLJWkQ"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 text-2xl hover:text-blue-500"
            >
              <FaTwitter />
            </a>
            <a
              href="https://www.instagram.com/mindlr.ai?igsh=cnU4MTRxZTh1YTZh"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-800 text-2xl hover:text-pink-500"
            >
              <FaInstagram />
            </a>
          </div>
        </div>
        {/* Divider Line */}
        <div className="mt-2 border-t-4 border-gray-800"></div>
      </div>

      {/* Main Footer Section */}
      <div className="py-10 px-6 md:px-16 lg:px-32 bg-tealcanva1">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left">
          {/* Platform Section */}
          <div className="px-3">
            <h2 className="text-lg font-montserrat font-semibold">Platform</h2>
            <ul className="mt-4 space-y-4 text-sm font-montserrat">
              <li>
                {/* Pass state so PlatformPage knows to scroll to "introduction" */}
                <Link
                  to="/platform"
                  state={{ section: "introduction" }}
                  className="hover:underline"
                >
                  Introduction
                </Link>
              </li>
              <li>
                {/* Pass state so PlatformPage knows to scroll to "quickstart" */}
                <Link
                  to="/platform"
                  state={{ section: "quickstart" }}
                  className="hover:underline"
                >
                  Quickstart
                </Link>
              </li>
              <li>
                {/* Pass state so PlatformPage knows to scroll to "blueprints" */}
                <Link
                  to="/platform"
                  state={{ section: "blueprints" }}
                  className="hover:underline"
                >
                  Blueprints or Workflows
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Section */}
          <div>
            <h2 className="text-lg font-semibold">Resources</h2>
            <ul className="mt-4 space-y-4 text-sm font-montserrat">
              <li>
                <Link to="/blog" className="hover:underline">
                  Blog
                </Link>
              </li>

              <li>
                <Link
                  to="/platform"
                  state={{ section: "faqs" }}
                  className="hover:underline"
                >
                  FAQ's & Support
                </Link>
              </li>

              <li>
                {/* Pass state so PlatformPage knows to scroll to "integration" */}
                <Link
                  to="/platform"
                  state={{ section: "integration" }}
                  className="hover:underline"
                >
                  Integration Playground
                </Link>
              </li>
            </ul>
          </div>

          {/* Company Section */}
          <div>
            <h2 className="text-lg font-semibold">Company</h2>
            <ul className="mt-4 space-y-4 text-sm font-montserrat">
              <li>
                <Link to="/about" className="hover:underline">
                  About
                </Link>
              </li>
              <li>
                <Link to="/about" className="hover:underline">
                  Our Vision
                </Link>
              </li>

              <li>
                <Link
                  to="/platform"
                  state={{ section: "introduction" }}
                  className="hover:underline"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="w-full bg-tealcanva2 py-6 mt-2">
        <div className="text-center text-sm text-gray-800">
          <p className="font-montserrat font-bold mb-4 mr-10">
            &copy;Copyright 2024 Mindlr Inc
          </p>
          <p className="mt-4 font-montserrat space-x-4 mr-6 font-bold">
            <Link to="/privacypolicy" className="hover:underline">
              Privacy Policy
            </Link>{" "}
            <Link to="/terms" className="hover:underline">
              Terms
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
}
