import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import ShimmerButton from "./ShimmerButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleJoinBetaClick = (e) => {
    e.preventDefault();
    navigate("/", { replace: true });
    setTimeout(() => {
      const contactForm = document.getElementById("contact-form");
      if (contactForm) {
        contactForm.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
    closeMenu();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.classList.add("overflow-hidden"); // Disable scrolling
    } else {
      document.body.classList.remove("overflow-hidden"); // Enable scrolling
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);

  return (
    <div className="bg-tealcanva1 flex justify-center w-full sticky z-50 top-0">
      <header className="rounded-b-2xl relative bg-tealcanva2 text-black font-bold font-poppins shadow-lg w-full">
        <div className="flex justify-between items-center py-4 px-5 max-w-screen-2xl mx-auto">
          {/* Logo */}
          <div className="flex items-center font-poppins font-bold text-black text-3xl">
            <Link to="/" className="flex items-center ml-4 md:ml-8">
              <img
                src="/images/mindrllogo.png"
                alt="Mindlr Logo"
                className="h-8 w-auto mr-2"
              />
              Mindlr
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-2xl mt-1">
              {isOpen ? <FaTimes /> : <FaBars />}
            </button>
          </div>

          {/* Mobile Dropdown Menu */}
          <nav
            ref={menuRef}
            className={`${
              isOpen ? "flex" : "hidden"
            } fixed top-16 left-0 w-full h-[calc(100vh-4rem)] bg-transparent backdrop-blur-lg text-black flex-col items-center z-50 transition-transform duration-300 ease-in-out space-y-6 p-6`}
          >
            <Link
              to="/sign-in"
              className="block text-lg font-poppins hover:underline"
              onClick={closeMenu}
            >
              Sign In
            </Link>
            <Link
              to="/register"
              className="block text-lg font-poppins hover:underline"
              onClick={closeMenu}
            >
              Register
            </Link>
            <Link
              to="/blog"
              className="block text-lg font-poppins hover:underline"
              onClick={closeMenu}
            >
              Blog
            </Link>
          </nav>

          {/* Desktop Menu */}
          <nav className="hidden md:flex md:items-center space-x-8 mr-8">
            <Link
              to="/register"
              className="flex items-center text-lg font-medium text-black space-x-2 group"
            >
              <span className="group-hover:underline font-dmsans">
                Register
              </span>
            </Link>

            <Link
              to="/sign-in"
              className="flex items-center text-lg font-medium text-black space-x-2 group"
            >
              <span className="group-hover:underline font-dmsans">Sign In</span>
              <div className="flex items-center justify-center w-6 h-6 bg-gray-300 text-white rounded-full">
                <span className="text-gray-600 material-icons text-lg">
                  person
                </span>
              </div>
            </Link>
          </nav>
        </div>
      </header>
    </div>
  );
};

export default Header;
