import React, { useState, useEffect, useRef } from "react";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FiLoader } from "react-icons/fi";
import NotificationPopup from "../components/NotificationPopup";
import { Link, useNavigate } from "react-router-dom";

const TopBar = ({
  handleLogout,
  searchEnabled,
  query,
  setQuery,
  handleSearchClicked,
  handleKeyPress,
  loading,
  searchUsersDropdown, // For user-suggestions dropdown
  searchUsersSuggestions,
  handleSearchUsersClick,
  notificationCount,
  setNotificationCount,
  handleNotificationClick,
  showNotificationPopup,
  setShowNotificationPopup,
  dropdownOpen,
  setDropdownOpen,
  settingsOpen,
  setSettingsOpen,
  setShowResetPasswordPopup,
  setShowLogoutPopup,
  userId,
  showHomeIcon,
  currentPage,
  navigateToHome,
  currentTab,
  setSearchUsersDropdown,
  isWelcomePage, // Add this prop
}) => {
  // Helper function for navigation override
  const handleRedirect = (defaultAction) => {
    if (isWelcomePage) {
      navigate("/sign-in"); // Redirect to sign-in if on the welcome page
    } else {
      defaultAction?.(); // Perform the default action if not on the welcome page
    }
  };

  const navigate = useNavigate();

  // Refs for “click outside” detection
  const dropdownRef = useRef(null); // 1) User-suggestions dropdown
  const notificationsRef = useRef(null); // 2) Notifications popup
  const settingsDropdownRef = useRef(null); // 3) Settings dropdown
  //const [searchUsersDropdown, setSearchUsersDropdown] = useState(false);

  // -- ADD THIS: combined outside-click effect for all dropdowns --
  //CLOSE THE DROPDOWNS BY CLICKING ANYWHERE OUTSIDE
  useEffect(() => {
    function handleClickOutside(event) {
      // 1) Close notifications dropdown if clicked outside
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setShowNotificationPopup(false);
      }

      // 2) Close settings dropdown if clicked outside
      if (
        settingsDropdownRef.current &&
        !settingsDropdownRef.current.contains(event.target)
      ) {
        setSettingsOpen(false);
      }

      // 3) Close user-suggestions dropdown if clicked outside
      //    Check that it’s open and ref is valid.
      if (
        searchUsersDropdown &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        // This line closes the user-suggestions dropdown
        // whenever the user clicks anywhere else.
        // ADD THIS ↓
        setSearchUsersDropdown(false);
      }

      // 4) Close the profile icon dropdown (the “Person” icon)
      //    if you’re using dropdownOpen for that.
      if (
        dropdownOpen &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    setShowNotificationPopup,
    setSettingsOpen,
    setDropdownOpen,

    // ADD THIS: we also watch searchUsersDropdown and
    // whatever function sets it (not shown in your snippet,
    // but presumably “setSearchUsersDropdown” in Profile).
    searchUsersDropdown,
    // setSearchUsersDropdown is presumably in the parent,
    // but you can leave it out if it’s not defined here.
  ]);
  // -- /ADD THIS: outside-click effect --

  return (
    <div className="flex-col bg-tealcanva6 p-3 rounded-none shadow-md ">
      {/* Main Content of Top Bar */}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center w-full md:w-3/4 mx-auto md:ml-64">
          <div className="relative flex items-center">
            {/* Mindlr Logo */}
            <div className="relative flex items-center">
              {/* Mindlr Logo */}
              <img
                src="/images/mindrllogo.png"
                alt="Mindlr Logo"
                className="h-8 md:h-10 w-10 md:w-14 mx-0 md:mx-2 mt-1  md:block cursor-pointer"
                style={{ marginBottom: "0.5rem" }}
                onClick={
                  () =>
                    isWelcomePage
                      ? navigate("/sign-in") // Navigate to sign-in if on the welcome page
                      : navigate("/home") // Navigate to home if not
                }
              />
            </div>
          </div>

          {/* Search Bar */}
          {searchEnabled && (
            <div className="relative w-full h-12 max-w-full md:max-w-3xl">
              <BsStars
                className="absolute left-4 top-1/2 transform -translate-y-1/2
                 text-tealcanva4 text-2xl md:text-3xl"
              />
              <input
                type="text"
                value={query}
                onChange={(e) =>
                  isWelcomePage
                    ? navigate("/sign-in")
                    : setQuery(e.target.value)
                }
                onKeyDown={(e) =>
                  isWelcomePage ? navigate("/sign-in") : handleKeyPress(e)
                }
                placeholder={
                  currentPage === "profile"
                    ? "Find people/professions"
                    : "Describe a situation to discover AI integrations"
                }
                onFocus={() => {
                  if (isWelcomePage) {
                    navigate("/sign-in");
                  } else if (currentPage !== "profile" && navigateToHome) {
                    navigate("/home", { state: { currentTab } });
                  }
                }}
                className="h-full pl-16 bg-white w-full border-2 border-gray-500
                 rounded-full text-black pr-12 text-lg focus:outline-none"
              />
              <div
                className="absolute right-2 top-1/2 transform -translate-y-1/2"
                onClick={() =>
                  isWelcomePage ? navigate("/sign-in") : handleSearchClicked()
                }
              >
                {loading ? (
                  <FiLoader className="animate-spin text-gray-400 text-3xl md:text-4xl" />
                ) : (
                  <div className="bg-tealcanva4 ml-2 rounded-full p-1 flex items-center justify-center cursor-pointer">
                    <CiSearch className="text-white text-3xl md:text-3xl" />
                  </div>
                )}
              </div>

              {/* Dropdown for Live User Suggestions */}
              {currentPage === "profile" && searchUsersDropdown && (
                <div
                  ref={dropdownRef}
                  className="absolute bg-white border border-gray-300 rounded-md shadow-lg z-50"
                >
                  {searchUsersSuggestions.slice(0, 5).map((suggestion) => (
                    <Link
                      to={`/uploader-profile/${suggestion.id}`}
                      key={suggestion._id}
                      className="block"
                    >
                      <div className="p-2 hover:bg-gray-200 cursor-pointer text-gray-800 flex items-center">
                        <img
                          src={suggestion.profilePic}
                          alt={suggestion.name}
                          className="w-8 h-8 rounded-full mr-3"
                        />
                        <span className="text-sm md:text-base font-medium">
                          {suggestion.name}
                        </span>
                        {suggestion.toolbox && (
                          <div className="ml-auto flex space-x-2">
                            {suggestion.toolbox.slice(0, 3).map((tool, idx) => (
                              <img
                                key={idx}
                                src={tool.image}
                                alt={tool.name}
                                className="w-6 h-6 rounded-full"
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Icons (Profile, Notifications, Settings) */}
        <div className="pl-20  justify-around w-1/4 space-x-4 hidden md:flex">
          {showHomeIcon && (
            <div className="relative">
              <span
                className="material-icons text-gray-600 text-3xl md:text-4xl hover:text-gray-400 cursor-pointer"
                onClick={() => navigate("/home")}
              >
                home
              </span>
            </div>
          )}

          {/* Profile Icon */}
          <div className="relative">
            <span
              className="hidden md:flex material-icons text-gray-600 text-3xl md:text-4xl hover:text-gray-400 cursor-pointer"
              onClick={
                isWelcomePage
                  ? () => navigate("/sign-in") // Redirect to sign-in if on the welcome page
                  : () => navigate("/profile") // Navigate to profile if not
              }
            >
              person
            </span>
            {dropdownOpen &&
              !isWelcomePage && ( // Only show dropdown if not on the welcome page
                <div
                  ref={dropdownRef}
                  className="absolute top-12 right-0 w-48 bg-white rounded-md shadow-lg z-50"
                >
                  <button
                    onClick={() => navigate("/profile")}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    View Profile
                  </button>
                  <button
                    onClick={handleLogout}
                    className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                  >
                    Log Out
                  </button>
                </div>
              )}
          </div>

          {/* Notifications Icon */}
          <div className="hidden md:flex relative">
            <span
              className="material-icons text-gray-600 text-3xl md:text-4xl hover:text-gray-400 cursor-pointer"
              onClick={
                isWelcomePage
                  ? () => navigate("/sign-in") // Redirect to sign-in if on the welcome page
                  : handleNotificationClick // Handle notifications if not
              }
            >
              notifications
            </span>
            {notificationCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center text-xs">
                {notificationCount}
              </span>
            )}
            {showNotificationPopup && (
              <div ref={notificationsRef}>
                <NotificationPopup
                  userId={userId}
                  onClose={() => setShowNotificationPopup(false)}
                />
              </div>
            )}
          </div>

          {/* Settings Icon */}
          <div className="relative hidden md:flex">
            <span
              className="material-icons text-gray-600 text-3xl md:text-4xl hover:text-gray-400 cursor-pointer"
              onClick={
                () =>
                  isWelcomePage
                    ? navigate("/sign-in") // Redirect to sign-in if on the welcome page
                    : setSettingsOpen((prev) => !prev) // Toggle dropdown if not
              }
            >
              settings
            </span>
            {settingsOpen && (
              <div
                ref={settingsDropdownRef}
                className="absolute top-12 right-0 w-48 bg-white rounded-md shadow-lg z-50"
              >
                <button
                  onClick={() => setShowResetPasswordPopup(true)}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Reset Password
                </button>
                <button
                  onClick={() => handleLogout()}
                  className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                >
                  Log Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
