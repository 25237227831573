import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-serif p-6 md:p-2">
      <div className="max-w-5xl mx-auto bg-white rounded-xl p-8 md:p-12">
        {/* Page Title */}
        <h1 className="text-4xl font-bold mb-4 text-center text-black">
          Privacy Policy
        </h1>
        <p className="text-lg text-gray-500 mb-8 text-center">
          Last Updated: <span className="font-medium">2025-01-16</span>
        </p>

        {/* Privacy Policy Content */}
        <div className="space-y-8 leading-relaxed">
          <p>
            Thank you for choosing <strong>Mindlr</strong>. This Privacy Policy
            explains how Mindlr (“we,” “us,” or “our”) collects, uses, shares,
            and safeguards your personal information. By accessing or using our
            website and services, you agree to the terms described in this
            Policy.
          </p>
          <hr className="border-t border-gray-300 my-4" />

          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">1. Scope</h2>
            <p>
              This Policy applies to all users of Mindlr’s services, including
              but not limited to visitors to our website, registered users, and
              any individuals who interact with Mindlr in connection with our
              services.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              2. Information We Collect
            </h2>
            <h3 className="text-xl font-medium mb-3">
              2.1 Personal Information
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Contact Details:</strong> We may collect your name and
                email address during account registration or when you subscribe
                to our newsletter.
              </li>
              <li className="text-lg">
                <strong>Social Media Profiles:</strong> If you choose to connect
                or log in via social media, we may collect your social media
                handle or profile information.
              </li>
            </ul>
            <p className="mt-3">
              <em>
                Note: We do not collect or process any sensitive data such as
                health information, biometric data, or special categories of
                personal data.
              </em>
            </p>

            <h3 className="text-xl font-medium mt-6 mb-3">2.2 Usage Data</h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Browser & Device Statistics:</strong> We collect
                technical information such as your IP address, browser type,
                operating system, and other usage details to better understand
                how visitors use our platform.
              </li>
              <li className="text-lg">
                <strong>User Behavior:</strong> We utilize analytics to monitor
                user behavior, traffic patterns, and overall engagement with
                Mindlr’s website and services.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              3. How We Use Your Information
            </h2>

            <h3 className="text-xl font-medium mb-3">
              3.1 Providing and Improving Our Services
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                Manage user accounts and provide customer support.
              </li>
              <li className="text-lg">
                Personalize the user experience and enhance platform
                performance.
              </li>
            </ul>

            <h3 className="text-xl font-medium mt-6 mb-3">
              3.2 Communications
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Email Newsletters:</strong> With your consent, we may
                send promotional or informational emails.
              </li>
              <li className="text-lg">
                <strong>Marketing Communications:</strong> If at any time you
                wish to withdraw consent for such communications, you have the
                right to opt out.
              </li>
            </ul>

            <h3 className="text-xl font-medium mt-6 mb-3">
              3.3 Transactions and Payments
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Payment Processing (if applicable):</strong> Should we
                offer paid features or services in the future, personal data may
                be used to process payments securely.
              </li>
            </ul>

            <h3 className="text-xl font-medium mt-6 mb-3">
              3.4 User-Generated Content
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Publishing Content:</strong> If you contribute
                user-generated content (e.g., articles, comments), we may
                display that content on our platform.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              4. Data Storage and Location
            </h2>
            <p>
              All data is stored and processed in the United States. By using
              our services, you acknowledge that your personal information may
              be transferred to and maintained on servers located in the U.S.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              5. Cookies and Tracking Technologies
            </h2>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Usage:</strong> We use cookies and similar tracking
                technologies to analyze user behavior, remember preferences, and
                optimize website performance.
              </li>
              <li className="text-lg">
                <strong>Managing Cookies:</strong> You can adjust your browser
                settings to block or delete cookies. Note that certain features
                of our website may not function properly if cookies are
                disabled.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Additional Sections */}
          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              6. Third-Party Services
            </h2>
            <p className="text-lg">
              Currently, Mindlr does not integrate with any third-party services
              that collect or process user data on our behalf. Should this
              change, we will update our Policy and clearly inform our users.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 7 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              7. Children’s Privacy
            </h2>
            <p className="text-lg">
              Our services are not intended for children under the age of 13. We
              do not knowingly collect or solicit personal information from
              individuals under 13. If you become aware of any data we have
              collected from children under 13, please contact us immediately.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 8 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">8. User Rights</h2>

            <h3 className="text-xl font-medium mb-3">
              8.1 Access and Correction
            </h3>
            <p className="text-lg">
              You have the right to request access to and obtain a copy of your
              personal information. You may also request corrections if any
              information is inaccurate or outdated.
            </p>

            <h3 className="text-xl font-medium mt-6 mb-3">
              8.2 Data Portability and Deletion
            </h3>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Download:</strong> a copy of your personal information
                in a portable format, where feasible.
              </li>
              <li className="text-lg">
                <strong>Request Deletion:</strong> of your personal information,
                subject to certain legal exceptions.
              </li>
            </ul>

            <h3 className="text-xl font-medium mt-6 mb-3">
              8.3 Withdrawal of Consent
            </h3>
            <p className="text-lg">
              You can withdraw your consent to marketing communications at any
              time by clicking the “unsubscribe” link in our emails or by
              contacting us directly.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 9 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              9. Compliance with Applicable Laws
            </h2>
            <p className="text-lg">
              Mindlr complies with all relevant U.S. state privacy laws,
              including those in California, Colorado, Delaware, and other
              states where applicable. For Canadian users, we adhere to the
              Personal Information Protection and Electronic Documents Act
              (PIPEDA). If you have any questions regarding our data protection
              practices, please reach out to us.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 10 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              10. Updates to This Privacy Policy
            </h2>
            <p className="text-lg">
              We may update this Privacy Policy from time to time. Any changes
              will be posted on our website with a revised “Last Updated” date.
              If we make significant modifications, we will provide a more
              prominent notice or directly notify you.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Repeat similar structure for all remaining sections */}

          <section>
            <h2 className="text-2xl font-semibold mb-4">11. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact:
            </p>
            <address className="mt-3">
              <strong>Mindlr</strong> <br />
              Attn: Naveen Sai Ganta <br />
              <a
                href="https://mindlr.ai/contact-us"
                className="text-blue-600 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mindlr.ai/contact-us
              </a>
            </address>
          </section>
        </div>
      </div>
    </div>
  );
}
