import React, { useState, useEffect, useRef } from "react";
import VideoCard from "../components/VideoCard";
import { BsStars } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { useNavigate, Link, useLocation } from "react-router-dom";
import NotificationPopup from "../components/NotificationPopup";
import Disclaimer from "../components/Disclaimer";
import FeedbackForm from "../components/FeedbackForm";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import WelcomePopup from "../components/WelcomePopup";
import ToolBuyPopup from "../components/ToolBuyPopup";
import { FaCoins } from "react-icons/fa";
import { IoIosThumbsUp } from "react-icons/io";
import { IoIosThumbsDown } from "react-icons/io";
import { FiLoader } from "react-icons/fi";
import VideoCard2 from "../components/VideoCard2";
import ContentLoader from "react-content-loader";
import TopBar from "../components/TopBar";
import { FiUserPlus, FiCheck } from "react-icons/fi"; // For follow and check icons
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import ToolMakerWelcome from "./ToolMakerWelcome";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VideoUploadPopup from "../components/VideoUploadPopup";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../features/user/userSlice";
import { logoutUser } from "../features/user/userSlice";

export default function WelcomePage() {
  //Redux implemented here :
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (userStatus === "idle") {
      // Means we haven't fetched yet
      dispatch(fetchUserData());
    }
  }, [userStatus, dispatch]);

  if (userStatus === "loading") {
    // console.log("loading");
  }

  // If there's an error
  if (userStatus === "failed") {
    //console.log("Error loading user data");
  }

  // If user is not logged in, maybe userData is null
  if (!userData) {
    //console.log("No user data found (not logged in?)");
  }

  //under redux
  const [searchSaved, setSearchSaved] = useState(false);
  const saveInitialSearch = async () => {
    const savedBlueprintData = sessionStorage.getItem("blueprintData");
    const savedLastSearchQuery = sessionStorage.getItem("lastSearchQuery");
    setLastSearchQuery(savedLastSearchQuery);

    if (
      savedBlueprintData &&
      savedLastSearchQuery &&
      !searchSaved &&
      userData
    ) {
      const blueprint = JSON.parse(savedBlueprintData);

      try {
        const savePayload = {
          query: savedLastSearchQuery,
          blueprint,
          user: {
            name: userData.name,
            email: userData.email,
          },
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/saveSearch`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(savePayload),
          }
        );

        if (response.ok) {
          // console.log("Initial search saved successfully.");
          setSearchSaved(true); // Mark as saved
        } else {
          //console.error("Failed to save initial search.");
        }
      } catch (error) {
        //console.error("Error saving initial search:", error);
      }
    }
  };
  useEffect(() => {
    if (!searchSaved && userData) {
      saveInitialSearch();
    }
  }, [searchSaved, userData]); // Trigger when userData is updated
  // Empty dependency array ensures it only runs once

  const [footerSettingsOpen, setFooterSettingsOpen] = useState(false);
  const footerSettingsRef = useRef(null);

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState([]);
  const [user, setUser] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showTestingText, setShowTestingText] = useState(false); // New state
  const [notificationCount, setNotificationCount] = useState(0);
  const [showNotificationPopup, setShowNotificationPopup] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState(false); // Initial state to show the disclaimer
  const navigate = useNavigate();
  const profilePicInputRef = useRef(null);
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
  const [popup1Shown, setPopup1Shown] = useState(false);
  const [popup2Shown, setPopup2Shown] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [textualResults, setTextualResults] = useState("");
  const [lastSearchQuery, setLastSearchQuery] = useState(null);
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [showRewritePrompt, setShowRewritePrompt] = useState(false); // Control to show or hide rewritten prompt
  const [threeSuggestions, setThreeSuggestions] = useState([]); // Store the rewritten prompt suggestions
  const [manualSearch, setManualSearch] = useState(false);
  const [blueprintData, setBlueprintData] = useState(null);
  const [toolImages, setToolImages] = useState([]);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const [nameSuggestions, setNameSuggestions] = useState([]); // Store suggestions
  const [showDropdown, setShowDropdown] = useState(false); // Control dropdown visibility
  const [isFlipped, setIsFlipped] = useState(false);
  const [customizePrompt, setCustomizePrompt] = useState("");
  const [customizedBlueprint, setCustomizedBlueprint] = useState([]);
  const [credits, setCredits] = useState(0);
  const [showBuyPopup, setShowBuyPopup] = useState(false);
  const settingsRef = useRef(null);
  const notificationsRef = useRef(null);
  const [currentlyPlayingVideo, setCurrentlyPlayingVideo] = useState(null); // Track the currently playing video
  const [popupStatusFetched, setPopupStatusFetched] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [latestCustomizePrompt, setLatestCustomizePrompt] = useState("");
  const [youtubeResults, setYoutubeResults] = useState([]);
  const [currentTab, setCurrentTab] = useState("blueprints");
  const [showPopup, setShowPopup] = useState(true);
  const [users, setUsers] = useState([]); // List of users
  const [following, setFollowing] = useState(new Set()); // Track followed users
  const [liveBlueprint, setLiveBlueprint] = useState(null);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const handleOpenPaymentModal = () => setShowPaymentModal(true);
  const handleClosePaymentModal = () => setShowPaymentModal(false);
  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  const [feedback, setFeedback] = useState("");

  useEffect(() => {
    const handleFooterClickOutside = (event) => {
      if (
        footerSettingsRef.current &&
        !footerSettingsRef.current.contains(event.target)
      ) {
        setFooterSettingsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleFooterClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleFooterClickOutside);
    };
  }, []);

  const handleToolSwitch = (stepIndex, currentToolName) => {
    const updatedBlueprint = [...liveBlueprint]; // Clone the liveBlueprint
    const correspondingStep = blueprintData.steps[stepIndex]; // Find the corresponding step in the main blueprint

    if (correspondingStep && correspondingStep.tools) {
      const toolArray = correspondingStep.tools;
      const currentToolIndex = toolArray.findIndex(
        (tool) => tool.tool_name === currentToolName
      );

      // Determine the next tool (cyclic behavior)
      const nextToolIndex =
        currentToolIndex === toolArray.length - 1 ? 0 : currentToolIndex + 1;

      // Update the tool in the live blueprint
      const nextTool = toolArray[nextToolIndex];
      updatedBlueprint[stepIndex] = {
        ...updatedBlueprint[stepIndex],
        tool_name: nextTool.tool_name,
        tool_link: nextTool.tool_link,
        tool_logo: nextTool.tool_logo,
        instruction: nextTool.instruction,
      };
    }

    setLiveBlueprint(updatedBlueprint);
    setLiveBlueprint(updatedBlueprint); // Update state to trigger re-render

    // Save the updated live blueprint in sessionStorage
    sessionStorage.setItem("liveBlueprint", JSON.stringify(updatedBlueprint));
    // Update state to trigger re-render
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state?.currentTab) {
      setCurrentTab(location.state.currentTab);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/people?query=${encodeURIComponent(
            query
          )}`,
          { method: "GET", credentials: "include" }
        );
        if (response.ok) {
          const data = await response.json();
          setUsers(data); // Update users state
        } else {
          console.error("Failed to fetch users");
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (user) fetchUsers(); // Ensure `user` is already fetched before fetching users
  }, [query, user]);

  const handleFollow = async (uploaderId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/follow/${uploaderId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: userData._id }),
          credentials: "include",
        }
      );

      if (response.ok) {
        dispatch(fetchUserData());
      } else {
        console.error("Failed to follow user");
      }
    } catch (error) {
      console.error("Error following user:", error);
    }
  };

  const showErrorToast = (message) => {
    toast.error(message, {
      autoClose: 2000, // Close after 2 seconds
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "errorToast",
    });
  };

  const showSuccessToast = (message) => {
    toast.success(message, {
      autoClose: 2000,
      hideProgressBar: true,
      pauseOnHover: false,
      toastId: "successToast",
    });
  };

  const handleProfileNavigation = (uploaderId) => {
    navigate(`/uploader-profile/${uploaderId}`);
  };

  const dropdownRef = useRef(null);

  const settingsDropdownRef = useRef(null); // For the dropdown container

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        settingsRef.current &&
        !settingsRef.current.contains(event.target) &&
        settingsDropdownRef.current &&
        !settingsDropdownRef.current.contains(event.target)
      ) {
        setSettingsOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false); // Close the dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target)
      ) {
        setShowNotificationPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowNotificationPopup]);

  const handleVideoPlay = (videoRef) => {
    if (currentlyPlayingVideo && currentlyPlayingVideo !== videoRef) {
      currentlyPlayingVideo.pause(); // Pause the previously playing video
    }
    setCurrentlyPlayingVideo(videoRef); // Set the new currently playing video
  };
  // Function to handle "Agree" action in the disclaimer

  // Check if disclaimer has already been accepted
  useEffect(() => {
    const isDisclaimerAccepted = localStorage.getItem("disclaimerShown");
    if (isDisclaimerAccepted) {
      setDisclaimerAccepted(true);
    }
  }, []);

  /*useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          fetchNotificationCount(userData?._id); // Fetch notifications count

          // Initialize the 'following' state
          const followingSet = new Set(userData?.following.map((u) => u._id));
          setFollowing(followingSet); // Assuming 'setFollowing' is defined in the component
        } else {
          console.error("Failed to fetch user info");
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);*/

  useEffect(() => {
    if (showFeedbackPopup) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      // Reset on unmount or else the page could remain stuck
      document.body.style.overflow = "auto";
    };
  }, [showFeedbackPopup]);

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert("Feedback cannot be empty.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feedback/${user?._id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ feedback }),
        }
      );

      if (response.ok) {
        alert("Feedback submitted successfully!");
        setShowFeedbackPopup(false);
        setFeedback("");
      } else {
        const errorData = await response.json();
        alert(`Error: ${errorData.error || "Failed to submit feedback."}`);
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("Failed to submit feedback. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  //time spent
  useEffect(() => {
    if (!userData || !userData.email) {
      console.warn("User is not defined or email is missing.");
      return;
    }

    const sessionStart = Date.now(); // Record session start time

    const logSessionTime = async () => {
      const sessionEnd = Date.now(); // Record session end time
      const sessionDuration = Math.floor((sessionEnd - sessionStart) / 1000); // Calculate in seconds

      //console.log("Session data being logged:", {
      // email: user?.email,
      //sessionStart,
      //sessionEnd,
      //sessionDuration,
      // });

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/log-session-time`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: user?.email, // Ensure this is correctly populated
              sessionStart,
              sessionEnd,
              sessionDuration,
            }),
          }
        );

        if (response.ok) {
          //console.log("Session logged successfully.");
        } else {
          const errorData = await response.json();
          //console.error("Failed to log session time:", errorData.message);
        }
      } catch (error) {
        //console.error("Failed to log session time:", error);
      }
    };

    // Attach the event listener for page unload
    window.addEventListener("beforeunload", logSessionTime);

    // Cleanup the event listener when the component unmounts
    return () => {
      logSessionTime(); // Log session time before unmount
      window.removeEventListener("beforeunload", logSessionTime);
    };
  }, [user]);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/get-credits`,
          {
            method: "GET",
            credentials: "include",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setUser((prevUser) => ({
            ...prevUser,
            credits: data.credits,
          }));
        } else {
          console.error("Failed to fetch credits");
        }
      } catch (error) {
        console.error("Error fetching credits:", error);
      }
    };

    const savedBlueprintData = sessionStorage.getItem("blueprintData");
    const savedLiveBlueprint = sessionStorage.getItem("liveBlueprint");
    if (savedBlueprintData) {
      setBlueprintData(JSON.parse(savedBlueprintData));
    }
    if (savedLiveBlueprint) {
      setLiveBlueprint(JSON.parse(savedLiveBlueprint));
    }

    fetchUserCredits();
  }, []);

  const checkAuthCookie = () => {
    return document.cookie.includes("token"); // Replace 'authToken' with your cookie name
  };

  useEffect(() => {
    const isAuthenticated = checkAuthCookie();

    if (isAuthenticated && !localStorage.getItem("disclaimerShown")) {
      // Show disclaimer only if the user is authenticated and hasn't seen the disclaimer yet
      setShowDisclaimer(true);
    }
  }, []);

  const handleAgree = () => {
    // Hide the disclaimer popup locally
    setShowDisclaimer(false);

    // Update the backend and state using updatePopupStatus
    updatePopupStatus("disclaimerAccepted");

    // Set the localStorage flag for immediate session-level prevention
    localStorage.setItem("disclaimerShown", "true");

    // Update local state to reflect disclaimer acceptance
    setDisclaimerAccepted(true);
  };

  const handleDisagree = () => {
    // Handle logic if the user disagrees
  };

  useEffect(() => {
    setSearchTriggered(true); // Mark search as triggered without a default search
  }, []);

  // Use another effect for handling the search when the query changes

  const fetchNotificationCount = async (userId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/count`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNotificationCount(data.unreadCount);
      } else {
        console.error("Failed to fetch notification count");
      }
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  const handleNotificationClick = async () => {
    //console.log(user._id);
    setShowNotificationPopup((prev) => !prev); // Toggle notification popup
    setNotificationsOpen((prev) => !prev);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/notifications/mark-read`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId: userData._id }),
        }
      );

      if (response.ok) {
        setNotificationCount(0);
      } else {
        console.error("Failed to mark notifications as read");
      }
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  //live name suggestions
  /*const fetchNameSuggestions = async (query) => {
    if (!query.trim()) {
      setNameSuggestions([]); // Clear suggestions if query is empty
      setShowDropdown(false);
      return;
    }

    try {
      const response = await fetch(
        `http://localhost:5001/api/people/suggestions?query=${encodeURIComponent(
          query
        )}`, // Backend endpoint for suggestions
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setNameSuggestions(data); // Update suggestions
        setShowDropdown(true); // Show dropdown
      } else {
        console.error("Failed to fetch name suggestions");
      }
    } catch (error) {
      console.error("Error fetching name suggestions:", error);
    }
  };*/

  const handleSearch = async (searchQuery = query, retry = false) => {
    if (searchQuery.split(" ").length < 4) {
      alert("Your query is too short. Can you please elaborate it slightly?");
      return;
    }
    //localStorage.removeItem("latestBlueprint");

    setLoading(true);
    setError(null);
    setSearchSubmitted(true);
    setLastSearchQuery(searchQuery);
    setAllImagesLoaded(false);

    let validToolNames = [];

    try {
      // Blueprint and Main Search API URLs
      const bpUrl = `https://app.mindlr.ai/searchblueprint?query=${encodeURIComponent(
        searchQuery
      )}`;
      const apiUrl = `https://app.mindlr.ai/search?query=${encodeURIComponent(
        searchQuery.trim()
      )}`;

      // Fetch Blueprint and Main Search Results
      const [blueprintResponse, mainResponse] = await Promise.all([
        fetch(bpUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }),
        fetch(apiUrl, {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }),
      ]);

      if (!blueprintResponse.ok || !mainResponse.ok) {
        throw new Error("Failed to fetch blueprint or main search results");
      }

      // Process Blueprint Data
      const blueprintData = await blueprintResponse.json();
      const searchData = await mainResponse.json();

      if (blueprintData.length === 0 && !retry) {
        return handleSearch(searchQuery, true);
      }
      //console.log(blueprintData);
      // Create LiveBlueprint

      const liveBlueprint = blueprintData.steps.map((step) => {
        // Remove "(Human Step)" from the step title and instruction
        const cleanStep = step.step.replace(/^\(Human Step\)\s*/i, ""); // Clean step.step
        const cleanInstruction =
          step.tools && step.tools.length > 0
            ? step.tools[0].instruction.replace(/^\(Human Step\)\s*/i, "") // Clean tool.instruction
            : step.step.replace(/^\(Human Step\)\s*/i, ""); // Clean step.step if no tools

        if (step.tools && step.tools.length > 0) {
          const tool = step.tools[0];
          return {
            step: cleanStep,
            tool_name: tool.tool_name || "N/A",
            tool_link: tool.tool_link || "N/A",
            tool_logo: tool.tool_logo || "N/A",
            instruction: cleanInstruction || "N/A",
            explanation: tool.explanation || "N/A",
          };
        } else {
          return {
            step: cleanStep,
            tool_name: "N/A",
            tool_link: "N/A",
            tool_logo: "N/A",
            instruction: cleanStep || "N/A", // Use the cleaned step text
            explanation: "N/A",
          };
        }
      });

      setBlueprintData(blueprintData); // Store the full blueprint data
      sessionStorage.setItem("blueprintData", JSON.stringify(blueprintData));
      sessionStorage.setItem("liveBlueprint", JSON.stringify(liveBlueprint));

      setLiveBlueprint(liveBlueprint); // Set LiveBlueprint to state
      //console.log("LiveBlueprint:", liveBlueprint);
      //console.log("blueprintData:", blueprintData);

      const savePayload = {
        query: searchQuery,
        blueprint: blueprintData,
        user: {
          name: userData.name, // Retrieved earlier via /api/user
          email: userData.email,
        },
      };

      const saveResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/saveSearch`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(savePayload),
        }
      );

      if (!saveResponse.ok) {
        console.error("Failed to save search");
      }

      setTextualResults(searchData.textual_results);
    } catch (error) {
      console.error("Error in handleSearch (Blueprint/Main):", error);
    } finally {
      setLoading(false);
    }

    // Fetch YouTube Videos
    try {
      const youtubeVideos = [];
      const queriedTools = new Set();

      for (const toolName of validToolNames) {
        if (queriedTools.has(toolName)) {
          continue;
        }

        queriedTools.add(toolName);

        const searchQuery = `${toolName} AI tool tutorial`.trim();

        const youtubeUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&type=video&q=${encodeURIComponent(
          searchQuery
        )}&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`;

        try {
          const youtubeResponse = await fetch(youtubeUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });

          if (!youtubeResponse.ok) {
            console.error(`Failed to fetch YouTube video for: ${toolName}`);
            continue;
          }

          const youtubeData = await youtubeResponse.json();

          if (youtubeData.items && youtubeData.items.length > 0) {
            const validVideos = youtubeData.items.filter(
              (item) => item.id && item.id.videoId
            );

            validVideos.forEach((item) => {
              youtubeVideos.push({
                title: item.snippet.title,
                description: item.snippet.description,
                videoId: item.id.videoId,
                thumbnail: item.snippet.thumbnails?.default?.url || "",
              });
            });
          } else {
            console.warn(`No valid YouTube videos found for: ${toolName}`);
          }
        } catch (error) {
          console.error(`Error fetching YouTube video for ${toolName}:`, error);
          continue;
        }
      }

      setYoutubeResults(youtubeVideos);
    } catch (error) {
      console.error("Error in fetching YouTube videos:", error);
      setYoutubeResults([]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };
  const handleSearchClicked = () => {
    handleSearch();
  };

  const handleProfilePicUpload = async (e) => {
    const formData = new FormData();
    formData.append("profilePic", e.target.files[0]);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/user/profile-pic`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedUser = await response.json();
        setUser(updatedUser);
        //console.log("Updated User:", updatedUser);
      } else {
        console.error("Failed to upload profile picture");
      }
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  const toggleSettings = () => {
    //setSettingsOpen(!settingsOpen);
    setSettingsOpen((prev) => !prev);
  };

  const handleLogout = async () => {
    // Clear localStorage and sessionStorage
    localStorage.removeItem("disclaimerShown");
    localStorage.removeItem("hasShownWelcomePopup");
    sessionStorage.removeItem("blueprintData"); // Clear blueprint data
    sessionStorage.removeItem("liveBlueprint"); // Clear live blueprint

    setBlueprintData(null);
    setLiveBlueprint(null);

    try {
      // Make a logout API call
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include",
      });

      if (response.ok) {
        // Reset Redux state
        dispatch(logoutUser());

        // Navigate to the sign-in page
        navigate("/sign-in");
      } else {
        console.error("Failed to log out");
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleResetPassword = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // This is important to send the cookies with the request
          body: JSON.stringify({
            userId: userData._id, // Send the user ID in the request body
            currentPassword,
            newPassword,
          }),
        }
      );

      if (response.ok) {
        alert("Password updated successfully");
        setShowResetPasswordPopup(false);
      } else {
        // Try to parse the response as JSON
        try {
          const data = await response.json();
          alert(`Failed to update password: ${data.message}`);
        } catch (jsonError) {
          // If parsing fails, display a general error message
          alert("Failed to update password: An error occurred.");
        }
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      alert("An error occurred while resetting the password.");
    }
  };

  const handleRewritePromptClick = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/rewrite-query`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query }), // Send the current query
        }
      );

      if (response.ok) {
        const data = await response.json();
        const suggestionsArray = data.data.split("\n").filter(Boolean); // Adjust this split logic if necessary
        setThreeSuggestions(suggestionsArray); // Save the rewritten suggestions
        setShowRewritePrompt(true); // Show the suggestions box
      } else {
        console.error("Error fetching query rewrites");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion); // Set the query to the clicked suggestion
    handleSearch(); // Trigger the search with the new suggestion
  };

  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleFeedbackForm = () => {
    setShowFeedbackForm(!showFeedbackForm);
  };

  const TypingText = ({ text }) => {
    return <p>{text}</p>; // Just return the entire sentence
  };

  const TextualResultsWithTyping = ({ textualResults }) => {
    const [activeTextIndex, setActiveTextIndex] = useState(0);

    // Split the textual results by sentences (splitting on periods, exclamation marks, or question marks followed by a space)
    const sentences = textualResults.split(/(?<=[.?!])\s+/);

    useEffect(() => {
      if (activeTextIndex < sentences.length) {
        const timer = setTimeout(() => {
          setActiveTextIndex((prev) => prev + 1);
        }, 1000); // Delay between sentences (you can adjust the 1000ms to make it faster/slower)

        return () => clearTimeout(timer); // Cleanup on unmount
      }
    }, [activeTextIndex, sentences]);

    return (
      <div className="textual-results p-4 mb-4 text-black font-poppins bg-gray-100 rounded-md">
        {/* Display each sentence progressively */}
        {sentences.slice(0, activeTextIndex + 1).map((sentence, index) => (
          <TypingText key={index} text={sentence.trim()} />
        ))}
      </div>
    );
  };

  const handleCustomizeBlueprint = async () => {
    //console.log("reached here");
    if (customizePrompt.split(" ").length < 4) {
      alert("Your prompt is too short. Can you please elaborate it slightly?");
      return;
    }

    setLatestCustomizePrompt(customizePrompt);

    // Check for sufficient credits
    if (user?.credits <= 0) {
      alert("You have no credits left to customize.");
      return;
    }

    // Ensure customization prompt is entered
    if (!customizePrompt.trim()) {
      alert("Please enter a customization prompt.");
      return;
    }

    // Build the payload with the FULL blueprint data
    const payload = {
      blueprint_title: blueprintData?.blueprint_title || "Untitled",
      query: lastSearchQuery || "",
      previous_blueprint: blueprintData?.steps || [],
      prompt: customizePrompt,
    };

    setLoading(true); // Show spinner

    try {
      // Send the customization request
      const response = await fetch("https://app.mindlr.ai/customizeblueprint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Server responded with status ${response.status}`);
      }

      const data = await response.json();
      // data => { blueprint_title: "...", steps: [...] }

      // Rebuild blueprintData
      setBlueprintData(data);
      // Rebuild liveBlueprint from the newly returned data
      const updatedLiveBlueprint = data.steps.map((step) => {
        if (step.tools && step.tools.length > 0) {
          const tool = step.tools[0];
          return {
            step: step.step,
            tool_name: tool.tool_name || "N/A",
            tool_link: tool.tool_link || "N/A",
            tool_logo: tool.tool_logo || "N/A",
            instruction: tool.instruction || step.step || "N/A",
            explanation: tool.explanation || "N/A",
          };
        } else {
          // No tools? Just keep the step as an instruction
          return {
            step: step.step,
            tool_name: "N/A",
            tool_link: "N/A",
            tool_logo: "N/A",
            instruction: step.step || "N/A",
            explanation: "N/A",
          };
        }
      });

      setLiveBlueprint(updatedLiveBlueprint);

      // Persist them in sessionStorage
      sessionStorage.setItem("blueprintData", JSON.stringify(data));
      sessionStorage.setItem(
        "liveBlueprint",
        JSON.stringify(updatedLiveBlueprint)
      );

      setLoading(false);

      // Save the customization to the backend
      try {
        const saveCustomizationResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/saveCustomization`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: lastSearchQuery,
              customizePrompt,
              customizedBlueprint: data,
              user: {
                name: userData.name,
                email: userData.email,
              },
            }),
          }
        );

        if (!saveCustomizationResponse.ok) {
          //console.error("Failed to save customization to the database.");
        }
      } catch (error) {
        //console.error("Error saving customization:", error);
      }

      // Deduct credits
      const deductCreditsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/deduct-credits`,
        {
          method: "POST",
          credentials: "include", // Include cookies for authentication
        }
      );

      if (!deductCreditsResponse.ok) {
        const errorData = await deductCreditsResponse.json();
        if (errorData.message === "Insufficient credits") {
          alert("You need to purchase more credits to customize further.");
          navigate("/toolmaker-welcome");
          return;
        }
        throw new Error("Failed to deduct credits.");
      }

      // Fetch the updated credits
      const creditsResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/get-credits`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (creditsResponse.ok) {
        const creditsData = await creditsResponse.json();
        setUser((prevUser) => ({
          ...prevUser,
          credits: creditsData.credits,
        }));
      } else {
        console.error("Failed to fetch updated credits.");
      }

      // Reset states
      setCustomizePrompt(""); // Clear the customize prompt
      setIsFlipped(false); // Flip back the card

      // YouTube scraping with updated blueprint
      const validToolNames = updatedLiveBlueprint
        .map((step) => step.tool_name)
        .filter((t) => t && t !== "N/A" && t.trim() !== "");

      const youtubeVideos = [];
      const queriedTools = new Set();

      for (const toolName of validToolNames) {
        if (queriedTools.has(toolName)) {
          continue; // Skip if the tool has already been queried
        }

        queriedTools.add(toolName);
        const searchQuery = `${toolName} AI tool tutorial`.trim();

        const youtubeUrl = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=1&type=video&q=${encodeURIComponent(
          searchQuery
        )}&key=AIzaSyBFuq1V0GDSBMWUMSrhoPklkmnyR33X22E`;

        try {
          const youtubeResponse = await fetch(youtubeUrl, {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          });

          if (!youtubeResponse.ok) {
            //console.error(`Failed to fetch YouTube video for: ${toolName}`);
            //console.error(
            // "YouTube API Response Status:",
            // youtubeResponse.status
            //);

            const errorBody = await youtubeResponse.json();
            //console.error("YouTube API Error Body:", errorBody);
            continue;
          }

          const youtubeData = await youtubeResponse.json();

          if (youtubeData.items && youtubeData.items.length > 0) {
            const validVideos = youtubeData.items.filter(
              (item) => item.id && item.id.videoId
            );

            validVideos.forEach((item) => {
              youtubeVideos.push({
                title: item.snippet.title,
                description: item.snippet.description,
                videoId: item.id.videoId,
                thumbnail: item.snippet.thumbnails?.default?.url || "",
              });
            });
          } else {
            //console.warn(`No valid YouTube videos found for: ${toolName}`);
          }
        } catch (error) {
          //console.error(`Error fetching YouTube video for ${toolName}:`, error);
          continue;
        }
      }

      setYoutubeResults(youtubeVideos);
    } catch (error) {
      // console.error("Error during customization:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Hide spinner
    }
  };

  const handleFeedback = async (feedback) => {
    showSuccessToast("Feedback Saved Successfully");
    try {
      if (!lastSearchQuery) {
        //console.error("No lastSearchQuery available");
        alert("No search query found. Please try again.");
        return;
      }

      if (!userData?.email) {
        //console.error("No user email available");
        alert("User not logged in. Please log in again.");
        return;
      }

      const payload = {
        user: {
          email: userData.email,
        },
        query: lastSearchQuery,
        feedback,
        customizePrompt: latestCustomizePrompt || null, // Pass customization prompt if available
      };

      //console.log("Sending feedback payload:", payload);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/saveFeedback`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save feedback.");
      }
    } catch (error) {
      console.error("Error saving feedback:", error);
      alert("Failed to save feedback. Please try again.");
    }
  };

  const [loadingUserData, setLoadingUserData] = useState(true);
  useEffect(() => {
    const fetchUserPopupStatus = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: "GET",
          credentials: "include",
        });
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
          setDisclaimerAccepted(userData?.disclaimerAccepted || false);
          setPopup1Shown(userData?.popup1Shown || false);
          setPopup2Shown(userData?.popup2Shown || false);
        } else {
          //console.error("Failed to fetch user data");
        }
      } catch (error) {
        //console.error("Error fetching user data:", error);
      } finally {
        setPopupStatusFetched(true); // Mark popup status as fetched
      }
    };

    fetchUserPopupStatus();
  }, []);

  const updatePopupStatus = async (popupType) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/update-popup-status`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: userData._id, popupType }),
        }
      );

      if (response.ok) {
        if (popupType === "disclaimerAccepted") setDisclaimerAccepted(true);
        if (popupType === "popup1Shown") setPopup1Shown(true);
        if (popupType === "popup2Shown") setPopup2Shown(true);
      } else {
        console.error(`Failed to update ${popupType}`);
      }
    } catch (error) {
      console.error(`Error updating ${popupType}:`, error);
    }
  };

  return (
    <div className="bg-white min-h-screen p-0 overflow-x-hidden">
      {/* Top Bar for Desktop View */}
      <div className="bg-gray-200 ">
        <div className="sticky top-0 z-50 bg-white shadow-md">
          <TopBar
            handleLogout={handleLogout}
            setNotificationCount={setNotificationCount}
            searchEnabled={true}
            query={query}
            setQuery={setQuery}
            handleSearchClicked={handleSearchClicked}
            handleKeyPress={handleKeyPress}
            loading={loading}
            showDropdown={showDropdown}
            nameSuggestions={nameSuggestions}
            userId={user?._id || null}
            handleSuggestionClick={handleSuggestionClick}
            dropdownOpen={dropdownOpen}
            setDropdownOpen={setDropdownOpen}
            settingsOpen={settingsOpen}
            setSettingsOpen={setSettingsOpen}
            setShowResetPasswordPopup={setShowResetPasswordPopup} // ADD THIS
            setShowLogoutPopup={setShowLogoutPopup} // ADD THIS
            notificationCount={notificationCount}
            handleNotificationClick={handleNotificationClick}
            showNotificationPopup={showNotificationPopup}
            setShowNotificationPopup={setShowNotificationPopup}
          />
          {/* TABS container */}
          <div className="bg-graycanva2 rounded-lg p-0 shadow-md">
            {/* 
    1) We wrap buttons in one row (`flex row`) at all sizes.
    2) We add `overflow-x-auto` so that on very narrow phones
       the row can scroll horizontally without wrapping/breaking.
    3) The default classes are for mobile; `md:` classes match 
       your original desktop styling. 
  */}
            <div
              className="md:ml-0 flex items-center justify-center mt-0 overflow-x-auto 
                  space-x-2      /* smaller gap on mobile */
                  md:space-x-12  /* original bigger gap on desktop */
  "
            >
              {/* BLUEPRINTS BUTTON */}
              <button
                className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm   /* mobile defaults */
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md /* original desktop classes */
        rounded-full font-dmsans font-bold transition-all duration-300
        ${
          currentTab === "blueprints"
            ? "bg-tealcanva6 text-black"
            : "bg-transparent text-black hover:bg-gray-200"
        }
      `}
                onClick={() => setCurrentTab("blueprints")}
              >
                BLUEPRINTS
              </button>

              {/* Divider */}
              <div
                className="
        h-10 w-[2px] bg-gray-400 mx-2   /* smaller on mobile */
        md:h-10                         /* original height on desktop */
      "
              />

              {/* USE CASES BUTTON */}
              <button
                className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md
        rounded-full font-dmsans font-bold transition-all duration-300
        ${
          currentTab === "useCases"
            ? "bg-tealcanva6 text-black"
            : "bg-transparent text-black hover:bg-gray-200"
        }
      `}
                onClick={() => setCurrentTab("useCases")}
              >
                USE CASES
              </button>

              {/* Divider */}
              <div
                className="
        h-10 w-[2px] bg-gray-400 mx-2
        md:h-10
      "
              />

              {/* PEOPLE AND TOOLKITS BUTTON */}
              <button
                className={`
        mt-1 mb-1 px-2 py-1 mx-2 text-sm
        md:mt-1 md:mb-1 md:px-4 md:py-1 md:mx-10 md:text-md
        rounded-full font-dmsans font-bold transition-all duration-300
        ${
          currentTab === "toolkits"
            ? "bg-tealcanva6 text-black"
            : "bg-transparent text-black hover:bg-gray-200"
        }
      `}
                onClick={() => setCurrentTab("toolkits")}
              >
                PEOPLE AND TOOLKITS
              </button>
            </div>
          </div>
        </div>

        {/* Top Bar for Mobile View */}

        {/* Sticky Footer for Mobile View */}
        <div>
          {/* Conditionally render the Disclaimer component */}
          {!disclaimerAccepted && popupStatusFetched && user && (
            <Disclaimer onAgree={handleAgree} onDisagree={handleDisagree} />
          )}

          {/* Sticky Footer - only show after the disclaimer is accepted */}
          {disclaimerAccepted && (
            <div className="fixed bottom-0 left-0 w-full bg-tealcanva2 p-4 flex justify-between items-center md:hidden z-50">
              <Link to="/profile">
                <img
                  src={userData?.profilePic || "/images/defaultprofpic1.jpg"}
                  className="rounded-full items-stretch  w-10 h-10 md:w-28 md:h-28  cursor-pointer"
                />
              </Link>

              <div className="relative">
                <span
                  className="material-icons text-black cursor-pointer"
                  style={{ fontSize: "32px" }}
                  onClick={handleNotificationClick}
                >
                  notifications
                </span>
                {notificationCount > 0 && (
                  <span
                    style={{
                      position: "absolute",
                      top: "-6px", // Adjust this value to move the notification count up or down
                      right: "-10px", // Adjust this value to move the notification count left or right
                      background: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "2px 6px",
                      fontSize: "14px",
                    }}
                  >
                    {notificationCount}
                  </span>
                )}
                {showNotificationPopup && userData && (
                  <NotificationPopup
                    onClose={() => setShowNotificationPopup(false)}
                    position="up"
                  />
                )}
              </div>

              <div className="relative">
                <span
                  className="material-icons text-black cursor-pointer"
                  style={{ fontSize: "32px" }}
                  onClick={() => setFooterSettingsOpen((prev) => !prev)}
                >
                  settings
                </span>

                {footerSettingsOpen && (
                  <div
                    ref={footerSettingsRef}
                    className="absolute right-0 bottom-12 w-48 bg-white rounded-md shadow-lg z-50"
                    onClick={(e) => e.stopPropagation()} // Prevent bubbling
                  >
                    <button
                      onClick={() => setShowResetPasswordPopup(true)}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Reset Password
                    </button>
                    <button
                      onClick={() => handleLogout()}
                      className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-200"
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          className={`flex bg-white  ${
            showFeedbackPopup ? "pointer-events-none" : ""
          }`}
        >
          {/* Left Sidebar for Desktop */}
          <div
            className="hidden md:flex py-4 mt-1 rounded-lg bg-white w-1/6 flex-col items-center justify-start"
            style={{
              position: "sticky", // Makes it sticky
              top: "120px", // Adjust this value based on your header height
              alignSelf: "flex-start", // Ensures proper alignment
            }}
          >
            <div className="flex flex-col items-center mt-4">
              {!user ? (
                <div className="flex flex-col items-center">
                  {/* React Content Loader for Skeleton */}
                  <ContentLoader
                    speed={2}
                    width={200}
                    height={150}
                    viewBox="0 0 200 150"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    {/* Profile Picture */}
                    <circle cx="100" cy="50" r="40" />
                    {/* Name */}
                    <rect
                      x="40"
                      y="100"
                      rx="5"
                      ry="5"
                      width="120"
                      height="20"
                    />
                    {/* Bio */}
                    <rect
                      x="30"
                      y="130"
                      rx="5"
                      ry="5"
                      width="140"
                      height="15"
                    />
                  </ContentLoader>
                </div>
              ) : (
                <div className="flex flex-col items-center mt-4 ml-2">
                  <Link to="/profile" className="flex flex-col items-center">
                    <img
                      src={
                        userData?.profilePic || "/images/defaultprofpic1.jpg"
                      }
                      alt="Profile"
                      className="rounded-full w-24 h-24 mb-2 border border-gray-300"
                    />
                    <h3 className="text-md font-sans font-semibold text-black text-center">
                      {userData?.name}
                    </h3>
                    <p className="text-xs text-gray-500 text-center">
                      {userData?.bio}
                    </p>
                  </Link>

                  {/* File Input for Profile Picture */}
                  <input
                    type="file"
                    id="profilePicInput"
                    ref={profilePicInputRef}
                    style={{ display: "none" }}
                    onChange={handleProfilePicUpload}
                  />

                  {/* Feedback Button */}
                  {/*<div className="mt-80 w-full flex justify-center">
                    <button
                      onClick={() => setShowFeedbackPopup(true)}
                      className="bg-tealcanva9 hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
                    >
                      Feedback
                    </button>
                  </div>*/}
                </div>
              )}
            </div>
            {/* Feedback Popup */}
          </div>
          {/* Main Content */}
          <div className="flex-1 p-1 rounded-none relative">
            {currentTab === "blueprints" && (
              <>
                <style>
                  {`
    /* Add this CSS for scrollbar customization */
    ::-webkit-scrollbar {
      width: 12px; /* Increased scrollbar width */
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.5); /* Color of the scrollbar */
      border-radius: 10px; /* Rounded scrollbar */
    }
    ::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
    }

    /* Hide scrollbar on mobile screens */
    @media (max-width: 768px) {
      ::-webkit-scrollbar {
        display: none;
      }
      * {
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
      }
    }
  `}
                </style>

                {loading && (
                  <div className="flex justify-center items-start pt-4">
                    <FiLoader className="animate-spin text-gray-600 text-4xl mt-16 md:mt-0" />
                  </div>
                )}
                {error && <div>{error}</div>}
                <div className=" text-black font-poppins mx-auto w-full bg-white  p-2 md:p-4 rounded-lg">
                  {liveBlueprint ? (
                    <div className="w-full mx-auto rounded-3xl p-4 bg-white border-2 border-tealcanva4">
                      {/* Outer Rectangle */}
                      <div className="p-4 rounded-3xl bg-none">
                        {/* Inner Rectangle (White Background) */}
                        <div className="flex flex-col md:flex-row justify-between items-center mb-4 relative">
                          <h2 className="ml-0 md:ml-60 text-md md:text-lg font-sans text-center text-black border-2 border-black rounded-full px-4 py-0 inline-block mr-2 md:mr-0">
                            {blueprintData.blueprint_title}
                          </h2>

                          {/* Thumbs Up/Down Buttons & Credit Box for Desktop */}
                          <div className="hidden md:flex mr-0 ml-20 md:ml-0 absolute md:bottom-0 right-0 md:right-4 flex space-x-4">
                            <button
                              className="text-green-500 text-2xl hover:text-green-400 focus:outline-none"
                              onClick={() => handleFeedback("liked", false)}
                            >
                              👍
                            </button>
                            <button
                              className="text-red-500 text-2xl hover:text-red-400 focus:outline-none"
                              onClick={() => handleFeedback("disliked", false)}
                            >
                              👎
                            </button>
                            <div
                              className="absolute mt-0 md:mt-1 right-1 md:right-5 text-black text-xs font-bold px-2 py-1 rounded-full shadow-md cursor-pointer"
                              style={{ top: "24px" }}
                              onClick={handleOpenPaymentModal}
                            >
                              {user?.credits || 0} credits
                            </div>
                          </div>

                          {/* Thumbs Up/Down Buttons & Credit Box for Mobile */}
                          <div className="flex flex-col md:hidden items-center mt-4 space-y-2">
                            <div className="flex space-x-4">
                              <button
                                className="text-green-500 text-2xl hover:text-green-400 focus:outline-none"
                                onClick={() => handleFeedback("liked", false)}
                              >
                                👍
                              </button>
                              <button
                                className="text-red-500 text-2xl hover:text-red-400 focus:outline-none"
                                onClick={() =>
                                  handleFeedback("disliked", false)
                                }
                              >
                                👎
                              </button>
                            </div>
                            <div
                              className="text-black text-xs font-bold px-2 py-1 rounded-full shadow-md cursor-pointer"
                              onClick={handleOpenPaymentModal}
                            >
                              {user?.credits || 0} credits
                            </div>
                          </div>

                          {showPaymentModal && (
                            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                              <div className="bg-white rounded-lg p-4 w-full max-w-lg max-h-[80vh] overflow-y-auto relative">
                                {/* Close Button */}
                                <button
                                  onClick={handleClosePaymentModal}
                                  className="absolute top-2 right-2 text-black text-lg font-bold"
                                >
                                  ✕
                                </button>

                                {/* Payment Content */}
                                <ToolMakerWelcome />
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Most Inner Rectangle (Teal Background) */}
                        <div className="p-4 rounded-3xl shadow-md bg-tealcanva6 relative">
                          {!isFlipped ? (
                            <div className="backface-hidden">
                              {liveBlueprint.map((step, index) => (
                                <div
                                  key={index}
                                  className="flex items-center mb-6 relative"
                                >
                                  {step.tool_name !== "N/A" && (
                                    <div className="flex flex-col items-center w-16">
                                      <a
                                        href={step.tool_link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="w-10 h-10 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                      >
                                        {step.tool_logo ? (
                                          <img
                                            src={step.tool_logo}
                                            alt={step.tool_name}
                                            className="w-full h-full object-contain rounded-full"
                                          />
                                        ) : (
                                          <span className="text-xs font-bold">
                                            {step.tool_name[0]}
                                          </span>
                                        )}
                                      </a>
                                      <div className="text-xs text-black font-bold font-poppins mt-1 text-center">
                                        {step.tool_name}
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className={`${
                                      step.tool_name !== "N/A" ? "ml-4" : ""
                                    } flex-1 bg-white p-2 border border-black rounded-none md:rounded-full flex items-center justify-between`}
                                  >
                                    <p className="text-sm text-black font-montserrat font-semibold">
                                      {step.instruction}
                                    </p>
                                  </div>
                                  {step.tool_name !== "N/A" && (
                                    <div className="relative group">
                                      <FaArrowRightArrowLeft
                                        className="text-gray-600 ml-2 cursor-pointer"
                                        onClick={() =>
                                          handleToolSwitch(
                                            index,
                                            step.tool_name
                                          )
                                        }
                                      />
                                      <div
                                        className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 bg-black text-white text-xs rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity"
                                        style={{ whiteSpace: "nowrap" }}
                                      >
                                        Click icon to change tool
                                      </div>
                                    </div>
                                  )}

                                  {index < liveBlueprint.length - 1 && (
                                    <div
                                      className="absolute left-8 border-l-2 border-2 border-black z-0"
                                      style={{
                                        top: "calc(100% + 2px)",
                                        height: "28px",
                                      }}
                                    ></div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="p-4 rounded-3xl shadow-md bg-tealcanva6 relative">
                              {!isFlipped ? (
                                <div className="backface-hidden">
                                  {/* Front Side of Blueprint */}
                                  <h2 className="text-lg font-poppins mb-4 text-center text-black">
                                    Blueprint Steps
                                  </h2>
                                  {liveBlueprint.map((step, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center mb-6 p-4 rounded-lg border border-gray-500"
                                    >
                                      {step.tool_name !== "N/A" && (
                                        <div className="flex flex-col items-center w-20">
                                          <a
                                            href={step.tool_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="w-12 h-12 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                          >
                                            {step.tool_logo ? (
                                              <img
                                                src={step.tool_logo}
                                                alt={step.tool_name}
                                                className="w-full h-full object-contain rounded-full"
                                              />
                                            ) : (
                                              <span className="text-xs font-bold">
                                                {step.tool_name[0]}
                                              </span>
                                            )}
                                          </a>
                                          <div className="text-xs text-black font-bold font-poppins mt-2 text-center">
                                            {step.tool_name}
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex-1 ml-4">
                                        <div className="bg-white p-2 rounded-lg border border-gray-500">
                                          <p className="text-sm text-black font-montserrat font-semibold">
                                            {step.instruction}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div className="backface-hidden">
                                  {/* Back Side: Customize Blueprint */}
                                  <h2 className="text-lg font-poppins mb-4 text-center text-black">
                                    Customize Your Blueprint
                                  </h2>
                                  {liveBlueprint.map((step, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center mb-6 p-4 rounded-lg border border-gray-500 flex-col md:flex-row"
                                    >
                                      {step.tool_name !== "N/A" && (
                                        <div className="flex flex-col items-center w-20">
                                          <a
                                            href={step.tool_link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="w-12 h-12 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                          >
                                            {step.tool_logo ? (
                                              <img
                                                src={step.tool_logo}
                                                alt={step.tool_name}
                                                className="w-full h-full object-contain rounded-full"
                                              />
                                            ) : (
                                              <span className="text-xs font-bold">
                                                {step.tool_name[0]}
                                              </span>
                                            )}
                                          </a>
                                          <div className="text-xs text-black mt-2 font-montserrat font-bold text-center">
                                            {step.tool_name}
                                          </div>
                                        </div>
                                      )}
                                      <div className="flex-1 ml-0 md:ml-4 mt-4 md:mt-0 w-full">
                                        <div className=" p-2 rounded-lg border border-black text-black font-montserrat">
                                          <p className="text-sm text-black font-montserrat font-semibold text-center">
                                            {step.instruction}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  <textarea
                                    placeholder="Enter your prompt to customize"
                                    className="w-full p-3 bg-white font-montserrat text-black rounded-lg border border-gray-500 mb-4"
                                    value={customizePrompt}
                                    onChange={(e) =>
                                      setCustomizePrompt(e.target.value)
                                    }
                                  />
                                  <div className="flex justify-center">
                                    <button
                                      type="button"
                                      onClick={handleCustomizeBlueprint}
                                      className="px-3 py-2 bg-teal-600 text-white rounded-md hover:bg-teal-500 focus:outline-none"
                                    >
                                      Submit Customization
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setIsFlipped(false);
                                        setCustomizePrompt("");
                                      }}
                                      className="ml-2 px-3 py-2 bg-red-400 text-white rounded-md hover:bg-red-500 focus:outline-none"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                  {loading && (
                                    <div className="flex flex-col justify-center items-center pt-4">
                                      <FiLoader className="animate-spin text-gray-600 text-4xl mt-16 md:mt-0" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {/* Customize Blueprint Button */}
                          <div className="flex justify-end">
                            <button
                              onClick={() => {
                                setIsFlipped(true);
                                setCustomizePrompt("");
                              }}
                              className="mt-2 px-2 py-0 bg-white text-black rounded-full border font-sans text-md font-semibold border-black hover:bg-tealcanva2 focus:outline-none"
                            >
                              Customize Blueprint
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    !searchSubmitted && (
                      <div className="w-full p-4 rounded-lg bg-tealcanva6 border border-gray-600">
                        <h2 className="text-lg font-montserrat font-semibold mb-4 text-center text-black">
                          Generate Workflow Blueprints
                        </h2>
                        <p className="text-center font-montserrat font-semibold text-black">
                          Welcome to Mindlr. Describe a task or situation where
                          AI could assist, and generate a customizable workflow
                          blueprint with recommended tools.
                        </p>
                      </div>
                    )
                  )}
                </div>
                {/* Video Results Section */}
                {results && results.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {results.map((video, index) => (
                      <VideoCard
                        key={index}
                        video={video}
                        query={lastSearchQuery}
                        handleVideoPlay={handleVideoPlay}
                      />
                    ))}
                  </div>
                ) : (
                  // No videos message
                  !loading &&
                  searchSubmitted &&
                  results &&
                  results.length === 0 && (
                    <div className="text-black font-poppins text-center mt-0">
                      {/*We currently don't have any user experiences for this situation.*/}
                    </div>
                  )
                )}
                {youtubeResults && youtubeResults.length > 0 ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                    {youtubeResults.map((video, index) => (
                      <VideoCard2 key={index} video={video} />
                    ))}
                  </div>
                ) : (
                  !loading &&
                  searchSubmitted &&
                  youtubeResults &&
                  youtubeResults.length === 0 && (
                    <div className="flex justify-center items-center mt-4">
                      {/* Content Loader Animation */}
                      <div className="animate-pulse flex flex-col space-y-4">
                        {/* Placeholder for YouTube Video Thumbnail */}
                        <div className="bg-gray-200 rounded-lg w-80 h-48"></div>
                        {/* Placeholder for YouTube Video Title */}
                        <div className="bg-gray-200 rounded-md h-6 w-3/4 mx-auto"></div>
                        {/* Add more placeholders as needed */}
                      </div>
                    </div>
                  )
                )}
              </>
            )}

            {currentTab === "toolkits" && (
              <>
                <div className="bg-white rounded-lg">
                  {users.map((user, index) => (
                    <div
                      key={user._id}
                      className={`flex items-center justify-between p-4 ${
                        index !== users.length - 1
                          ? "border-b border-gray-300"
                          : ""
                      }`}
                    >
                      {/* User Details */}
                      <div className="flex items-center">
                        <img
                          src={user.profilePic || "/images/defaultprofpic1.jpg"}
                          alt="Profile"
                          className="rounded-full w-14 h-14 border border-gray-300 cursor-pointer"
                          onClick={() => handleProfileNavigation(user._id)}
                        />
                        <div className="ml-4">
                          <h3 className="text-lg font-poppins font-light text-gray-800">
                            {user.name}
                          </h3>
                          <p className="text-sm text-gray-500">{user.bio}</p>

                          {/* Tools Section */}
                          <div className="flex flex-wrap mt-2">
                            {user.toolbox.map((tool, index) => (
                              <a
                                key={index}
                                href={tool.tool?.Link || "#"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="w-8 h-8 m-1"
                              >
                                <img
                                  src={
                                    tool.tool?.Image ||
                                    "/images/defaulttool.png"
                                  }
                                  alt={tool.tool?.Name || "Tool"}
                                  className="w-full h-full rounded-full border border-gray-300"
                                />
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* Follow Button */}
                      {userData?.following?.some(
                        (followingUser) => followingUser._id === user._id
                      ) ? (
                        <FiCheck className="text-green-500 text-2xl" />
                      ) : (
                        <FiUserPlus
                          className="text-gray-500 text-2xl cursor-pointer hover:text-gray-400"
                          onClick={() => handleFollow(user._id)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </>
            )}

            {currentTab === "useCases" && (
              <div className="relative w-full h-full p-8">
                {/* Popup */}
                {showPopup && (
                  <div className="relative bg-tealcanva6 border rounded-lg shadow-md p-6 text-center font-sans">
                    <div className="absolute top-2 right-2">
                      <button
                        onClick={() => setShowPopup(false)}
                        className="text-black text-2xl font-bold hover:text-black focus:outline-none"
                      >
                        <RxCrossCircled />
                      </button>
                    </div>
                    <h2 className="text-lg font-sans font-semibold mb-1 text-black">
                      Discover AI use cases and share your own!
                    </h2>
                    <p className="mt-1 text-black font-sans">
                      Post a video of how you use any kind of an AI tool - Gain
                      rewards for your contribution.
                    </p>
                    <p className="mt-4 text-black font-sans">
                      Already creating amazing AI-related content on social
                      media?
                    </p>
                    <p className="mt-2 text-black font-sans">
                      Link it here and inspire others while reaching more of the
                      audience!
                    </p>
                  </div>
                )}

                {/* Mobile-only “Create a Post” button */}
                <div className="mt-6 md:hidden">
                  <button
                    className="bg-tealcanva9 text-sm hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
                    onClick={() => {
                      setShowUploadPopup(true);
                      setShowPopup(false);
                    }}
                  >
                    Create a post
                  </button>
                </div>

                {/* Possibly other content here... */}

                {/* The Upload Popup */}
                {showUploadPopup && (
                  <VideoUploadPopup
                    onClose={() => {
                      setShowUploadPopup(false);
                      setShowPopup(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="hidden md:flex bg-white opacity-90 w-1/6  p-4 rounded-lg flex-col mt-1 mr-1 mb-1">
            {/* Create a post button */}
            {currentTab === "useCases" && (
              <div className="absolute mt-100 ml-400 ">
                <button
                  className="bg-tealcanva9 text-sm hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
                  onClick={() => {
                    setShowUploadPopup(true);
                    setShowPopup(false);
                  }}
                >
                  Create a post
                </button>
              </div>
            )}
            {showUploadPopup && (
              <VideoUploadPopup
                onClose={() => {
                  setShowUploadPopup(false);
                  setShowPopup(true);
                }}
              />
            )}
            {/* Feedback Button */}
            {/*<div className="fixed bottom-4 mb-2 right-4 mr-14 md:bottom-6 md:right-6 z-50">
              <button
                // Toggle the popup
                className="bg-tealcanva9 text-sm hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
              >
                Help/Support
              </button>
            </div>{" "}*/}
            {searchSubmitted && manualSearch && !loading && (
              <div className="mb-4">
                {/*<p className="text-white font-poppins mb-2 ml-8">
                For better results click on:
              </p>*/
                /*<button
                className="p-2 bg-slate-900 ml-16 text-white font-poppins rounded-md justify-center"
                onClick={handleRewritePromptClick}
              >
                Rewrite prompt
              </button>*/}
              </div>
            )}
            {/* Show suggestions if available */}
            {showRewritePrompt && threeSuggestions.length > 0 && (
              <div>
                {threeSuggestions.map((suggestion, index) => (
                  <button
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="p-2 bg-slate-900 text-white font-poppins rounded-md mb-2 w-full"
                  >
                    {suggestion}
                  </button>
                ))}
              </div>
            )}
          </div>{" "}
          <div className="bg-white md:hidden w-1/8"></div>
        </div>
        {/*till here case blueprint*/}

        {/* Logout Confirmation Popup */}
        {showLogoutPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-xl font-bold font-poppins text-black mb-4">
                Are you sure?
              </h2>
              <p className="mb-6">Do you want to log out?</p>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowLogoutPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  No
                </button>
                <button
                  onClick={handleLogout}
                  className="bg-red-500 text-white px-4 py-2 rounded-full"
                >
                  Yes, Log Out
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Reset Password Popup */}
        {showResetPasswordPopup && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold text-black mb-4">
                Reset Password
              </h2>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">
                  Current Password
                </label>
                <input
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 font-bold mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div className="flex justify-around">
                <button
                  onClick={() => setShowResetPasswordPopup(false)}
                  className="bg-gray-300 text-gray-800 px-4 py-2 rounded-full"
                >
                  Cancel
                </button>
                <button
                  onClick={handleResetPassword}
                  className="bg-blue-500 text-black px-4 py-2 rounded-full"
                >
                  Update Password
                </button>
              </div>
            </div>
          </div>
        )}
      </div>{" "}
      {/* Feedback Button */}
      {!showUploadPopup && (
        <div className="fixed bottom-20 right-6 md:bottom-8 md:right-8 z-50">
          <FeedbackForm userId={userData?._id} />
        </div>
      )}
      <ToastContainer />
    </div>
  );
}
