import React, { useState, useEffect } from "react";
import { FaUser } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const sections = [
  {
    id: "introduction",
    title: "Introduction",
    content: `AI is evolving faster than ever, with new tools and models emerging every day. Yet, for many of us, the abundance of options creates a gap between selection and implementation. At Mindlr, we bridge this gap.

    We make AI tools approachable by helping you visualize their usage, offering step-by-step plans, and providing a space to learn from others. Whether you're looking to enhance your work, simplify personal tasks, or explore the AI wave, Mindlr ensures you achieve practical results, not just theoretical knowledge.`,
  },
  {
    id: "quickstart",
    title: "Quickstart",
    content: `Start your AI journey in 3 simple steps:
    1. Discover: Tell us what you need—describe your task or goal, and we'll recommend tools.
    2. Learn: Use blueprints to follow a clear, actionable step-by-step plan.
    3. Apply: Visualize how others are using AI, upload your results, and build your workflows.

    We’ve designed Mindlr to be intuitive and actionable from day one. No matter your background, AI just got easier!`,
  },
  {
    id: "blueprints",
    title: "Blueprints or Workflows",
    content: `Blueprints are step-by-step processes designed to help you accomplish a task. Each blueprint identifies what needs to be done by you and what can be handled by an AI tool.

    If the recommended tools don’t align with your preferences—whether due to cost, familiarity, or availability—you can easily customize them by clicking the 'Customize' button. Blueprints ensure you have a flexible, actionable plan that combines human creativity with AI-powered efficiency.`,
  },
  {
    id: "integration",
    title: "Integration Playground",
    content: `Imagine a place where you can experiment, learn, and build—all in one. That’s the Integration Playground.

    - Visual Learning: See workflows come to life.
    - Experiment Freely: Combine and test AI tools to fit your needs.
    - Profile Showcase: Share your workflows, tools, and successes with others.

    The Playground helps you go beyond discovery to active implementation. It’s where creativity meets productivity.`,
  },
  {
    id: "faqs",
    title: "FAQs",
    content: [],
  },
];

function scrollWithOffset(element) {
  const yOffset = -120; // Adjust this offset as needed for your sticky header.
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
}

export default function PlatformPage() {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState("introduction");
  const [expandedQuestions, setExpandedQuestions] = useState([]);

  const faqItems = [
    {
      question: "What is Mindlr?",
      answer:
        "Mindlr is a platform that bridges the gap between discovering and implementing AI tools by providing actionable blueprints.",
    },
    {
      question: "How does the blueprint system work?",
      answer:
        "Blueprints provide step-by-step guidance on how to integrate AI tools into your workflows. Each blueprint is customizable to fit your specific needs.",
    },
    {
      question: "Can I use my own tools in the blueprints?",
      answer:
        "Yes, you can customize blueprints and replace recommended tools with your own preferred tools.",
    },
  ];

  const toggleQuestion = (index) => {
    setExpandedQuestions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  useEffect(() => {
    if (location.state?.section) {
      const targetId = location.state.section;
      const element = document.getElementById(targetId);
      if (element) {
        scrollWithOffset(element);
        setActiveSection(targetId);
      }
    }
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 3;

      const visibleSection = sections.find((section) => {
        const element = document.getElementById(section.id);
        if (!element) return false;
        const top = element.offsetTop;
        const bottom = element.offsetTop + element.offsetHeight;
        return scrollPosition >= top && scrollPosition < bottom;
      });

      if (visibleSection) {
        setActiveSection(visibleSection.id);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToSection = (id) => {
    const target = document.getElementById(id);
    if (target) {
      scrollWithOffset(target);
      setActiveSection(id);
    }
  };

  return (
    <div className="bg-white font-montserrat">
      {/* Header */}
      <header
        className="bg-[#2197bd] text-white py-4 px-6 flex items-center justify-between sticky top-0 z-20"
        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
      >
        <Link to="/" className="flex items-center">
          <img
            src="/images/mindrllogo.png"
            alt="Mindlr Logo"
            className="h-8 mr-2"
          />
          Mindlr
        </Link>

        <Link
          to="/sign-in"
          className="bg-black mr-0 md:mr-16 text-white font-bold flex items-center justify-center px-2 py-1 rounded-full border-2 border-white shadow-md hover:bg-gray-800 transition"
          style={{ fontSize: "1rem" }}
        >
          <span className="mr-2">Sign In</span>
          <div
            className="flex items-center justify-center bg-white text-black rounded-full"
            style={{ width: "24px", height: "24px" }}
          >
            <FaUser size={16} />
          </div>
        </Link>
      </header>

      {/* Content Wrapper */}
      <div className="flex">
        {/* Sidebar - Hidden on Mobile */}
        <nav className="hidden md:block w-1/6 sticky top-[4rem] bg-white p-6 text-black z-10 h-[calc(100vh-4rem)]">
          <h2 className="text-2xl mt-20 mb-4">Platform</h2>
          <ul className="space-y-4">
            {sections.map((section) => (
              <li key={section.id}>
                <button
                  onClick={() => scrollToSection(section.id)}
                  className={`${
                    activeSection === section.id
                      ? "font-bold text-[#2197bd]"
                      : ""
                  } hover:underline text-left`}
                >
                  {section.title}
                </button>
              </li>
            ))}
          </ul>
        </nav>

        {/* Divider */}
        <div className="hidden md:block w-[4px] bg-black"></div>

        {/* Main Section */}
        <div className="w-full md:w-3/4 px-8 py-6 mt-20 bg-white text-black">
          {sections.map((section, index) => (
            <section
              key={section.id}
              id={section.id}
              className={`mb-12 ${
                index === sections.length - 1 ? "pb-96" : ""
              }`}
            >
              <h2 className="text-xl mb-2">{section.title}</h2>
              <hr className="border-t-4 border-gray-800 mb-4 w-[98%]" />
              <p className="text-gray-800 font-poppins whitespace-pre-line">
                {section.content}
              </p>

              {/* FAQs Section */}
              {section.id === "faqs" && (
                <div className="space-y-4">
                  {faqItems.map((item, index) => (
                    <div key={index} className="border-b border-gray-300 pb-4">
                      <div
                        className="flex justify-between items-center cursor-pointer"
                        onClick={() => toggleQuestion(index)}
                      >
                        <h3 className="text-lg font-bold">{item.question}</h3>
                        <span>
                          {expandedQuestions.includes(index) ? "▲" : "▼"}
                        </span>
                      </div>
                      {expandedQuestions.includes(index) && (
                        <p className="mt-2 text-gray-700">{item.answer}</p>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </section>
          ))}
        </div>
      </div>
    </div>
  );
}
