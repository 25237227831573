"use client"; // Include this only if you're using Next.js 13+ (app router)

import React, { useEffect, useRef, useState } from "react";
// Import from Framer Motion:
import { motion } from "framer-motion";

export function TypingAnimation({
  children,
  className,
  duration = 100,
  delay = 0,
  as = "div",
  startOnView = false,
  ...props
}) {
  // Dynamically choose the motion component type (default to motion.div):
  const MotionComponent = motion[as] || motion.div;

  const [displayedText, setDisplayedText] = useState("");
  const [started, setStarted] = useState(false);
  const elementRef = useRef(null);

  // Decide whether to start right away or on scroll into view:
  useEffect(() => {
    if (!startOnView) {
      // Start immediately after the optional `delay`:
      const startTimeout = setTimeout(() => setStarted(true), delay);
      return () => clearTimeout(startTimeout);
    }

    // If `startOnView` is true, we use IntersectionObserver:
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setTimeout(() => setStarted(true), delay);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [delay, startOnView]);

  // Type each character one by one:
  useEffect(() => {
    if (!started) return;

    let i = 0;
    const typingEffect = setInterval(() => {
      if (i < children.length) {
        setDisplayedText(children.substring(0, i + 1));
        i++;
      } else {
        clearInterval(typingEffect);
      }
    }, duration);

    return () => clearInterval(typingEffect);
  }, [children, duration, started]);

  return (
    <MotionComponent
      ref={elementRef}
      // Combine default classes and the optional className:
      className={`text-xs whitespace-nowrap md:text-xl text-black font-sans md:ml-36 md:mr-10 font-semibold leading-[5rem] tracking-[-0.02em] text-center ${
        className || ""
      }`}
      {...props}
    >
      <span className="inline-block">{displayedText}</span>
    </MotionComponent>
  );
}
