import { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";

const WelcomePopup = ({ onDismiss, popup1Shown, loadingUserData }) => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!loadingUserData && !popup1Shown) {
      // Add a delay of 1 second before showing the popup
      const timer = setTimeout(() => {
        setShowPopup(true);
      }, 1000); // 1-second delay

      return () => clearTimeout(timer); // Clean up on unmount
    }
  }, [loadingUserData, popup1Shown]);

  const handlePopupDismiss = () => {
    setShowPopup(false); // Hide the popup
    if (onDismiss) {
      onDismiss(); // Notify parent to update backend
    }
  };

  return (
    <>
      {showPopup && (
        <div
          className="absolute top-0 left-0 transform translate-y-1/2 z-50 bg-white text-black p-2 rounded-md shadow-lg w-56"
          style={{
            top: "50%",
            left: "-16rem", // Adjust the left value to move the box
            transform: "translateY(-50%)",
          }}
        >
          {/* Popup content */}
          <div className="text-xs ml-4 font-medium">
            Search what you are looking for
          </div>
          <div className="flex justify-center mt-2">
            <button
              onClick={handlePopupDismiss}
              className="bg-gray-800 text-white text-xs px-4 py-1 rounded-md"
            >
              OK
            </button>
          </div>

          {/* Arrow pointing right, positioned outside the box */}
          <div className="absolute right-[-15px] top-1/2 transform -translate-y-1/2">
            <FaArrowRight className="text-white text-lg" />
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomePopup;
