import React, { useEffect, useState } from "react";
import {
  IoIosThumbsUp,
  IoIosThumbsDown,
  IoIosRefresh,
  IoMdCheckmark,
} from "react-icons/io";
import { FiLoader } from "react-icons/fi";

export default function BlueprintsReview() {
  const [blueprints, setBlueprints] = useState([]); // Original blueprints
  const [reGeneratedBlueprints, setReGeneratedBlueprints] = useState({}); // Re-generated blueprints
  const [manualEdits, setManualEdits] = useState({}); // Manual edits
  const [feedback, setFeedback] = useState({}); // Feedback states
  const [isSubmitting, setIsSubmitting] = useState(false); // Submission state
  const [loadingBlueprints, setLoadingBlueprints] = useState({}); // Loading state per blueprint
  const [originalSteps, setOriginalSteps] = useState({}); // Store original regenerated steps
  const [openQueries, setOpenQueries] = useState({}); // To toggle dropdown visibility

  useEffect(() => {
    Object.keys(reGeneratedBlueprints).forEach((index) => {
      if (!originalSteps[index]) {
        setOriginalSteps((prev) => ({
          ...prev,
          [index]: JSON.parse(JSON.stringify(reGeneratedBlueprints[index])), // Deep clone
        }));
      }
    });
  }, [reGeneratedBlueprints, originalSteps]);

  // Fetch search history on load
  useEffect(() => {
    const fetchSearchHistory = async () => {
      try {
        // 1. Fetch search history from AlphaUser schema
        const searchResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/review-blueprints`
        );
        const searchHistory = searchResponse.ok
          ? await searchResponse.json()
          : [];

        // 2. Fetch BlueprintFeedback data
        const feedbackResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/blueprints-feedback`
        );
        const feedbackData = feedbackResponse.ok
          ? await feedbackResponse.json()
          : [];

        // 3. Merge data: Check which queries exist in feedbackData
        const mergedData = searchHistory.map((blueprint) => {
          const matchingFeedback = feedbackData.find(
            (feedback) => feedback.query.trim() === blueprint.query.trim()
          );
          return matchingFeedback
            ? { ...matchingFeedback }
            : { ...blueprint, versions: [] };
        });

        // 4. Set state with merged data
        setBlueprints(mergedData);
      } catch (error) {
        console.error("Error fetching blueprints:", error);
      }
    };

    fetchSearchHistory();
  }, []);

  // Handle thumbs up
  const handleThumbsUp = async (blueprint, index) => {
    if (feedback[index]?.verified) return;

    if (window.confirm("Submit as positive feedback?")) {
      setIsSubmitting(true);

      let steps = blueprint.blueprint;
      let versionName = "Base";

      // If regenerated blueprint exists, submit it as AfterSystemPrompt
      if (reGeneratedBlueprints[index]) {
        steps = reGeneratedBlueprints[index];
        versionName = "AfterSystemPrompt";
      }

      // Submit HumanFeedback if manual edits were done
      if (manualEdits[index]) {
        versionName = "HumanFeedback";
      }

      await submitFeedback(blueprint, "positive", steps, index, versionName);

      // Immediately mark it as verified
      setFeedback((prev) => ({
        ...prev,
        [index]: { verified: true },
      }));

      setBlueprints((prev) => {
        const updated = [...prev];
        updated[index] = {
          ...updated[index],
          versions: [
            ...(updated[index].versions || []),
            {
              version_name: versionName,
              steps,
              feedback: { status: "positive" },
            },
          ],
        };
        return updated;
      });

      setIsSubmitting(false);
    }
  };

  // Handle thumbs down
  const handleThumbsDown = async (blueprint, index) => {
    // 1. Handle Base Version Thumbs Down
    if (!feedback[index]?.baseSubmitted) {
      await submitFeedback(
        blueprint,
        "negative",
        blueprint.blueprint,
        index,
        "Base"
      );

      setFeedback((prev) => ({
        ...prev,
        [index]: { ...prev[index], baseSubmitted: true },
      }));
      //return; // Stop here for "Base" thumbs down
    }

    // 2. Handle AfterSystemPrompt Thumbs Down
    if (
      reGeneratedBlueprints[index] &&
      !feedback[index]?.afterSystemSubmitted
    ) {
      // Submit AfterSystemPrompt as negative
      await submitFeedback(
        blueprint,
        "negative",
        reGeneratedBlueprints[index],
        index,
        "AfterSystemPrompt"
      );

      setFeedback((prev) => ({
        ...prev,
        [index]: { ...prev[index], afterSystemSubmitted: true },
      }));

      setManualEdits((prev) => ({ ...prev, [index]: true })); // Enable manual editing
      return; // Stop further processing
    }

    // 3. Regenerate Blueprint if Not Already Done
    if (!reGeneratedBlueprints[index]) {
      setLoadingBlueprints((prev) => ({ ...prev, [index]: true }));

      const newBlueprint = await fetchBlueprint(blueprint.query);
      setReGeneratedBlueprints((prev) => ({
        ...prev,
        [index]: newBlueprint,
      }));

      setLoadingBlueprints((prev) => ({ ...prev, [index]: false }));
    }
  };

  // Fetch new blueprint from bpUrl
  const fetchBlueprint = async (query) => {
    try {
      const bpUrl = `https://app.mindlr.ai/searchblueprint?query=${encodeURIComponent(
        query
      )}`; // Corrected parentheses and string interpolation

      const response = await fetch(bpUrl);

      if (response.ok) {
        const blueprintData = await response.json();
        return blueprintData.map((step) => ({
          step: step.step,
          tool_logo: step.tool_logo,
        }));
      }
    } catch (error) {
      console.error("Error fetching blueprint:", error);
    }
    return [];
  };

  // Submit feedback
  const submitFeedback = async (
    blueprint,
    status,
    steps,
    index,
    versionName,
    correctedSteps = []
  ) => {
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/blueprints-feedback`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: blueprint.userId,
          query: blueprint.query,
          versionName: versionName || "Base",
          steps,
          feedbackStatus: status,
          correctedSteps, // Pass corrected steps explicitly
        }),
      });
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleSubmitManualEdit = async (blueprint, index) => {
    if (window.confirm("Submit your edited blueprint?")) {
      setIsSubmitting(true);

      const editedSteps = reGeneratedBlueprints[index];
      const initialSteps = originalSteps[index] || []; // Use stored original steps

      // Compare original and edited steps to extract corrected ones
      const correctedSteps = editedSteps
        .map((step, idx) => {
          return step.step !== initialSteps[idx]?.step ? step.step : null;
        })
        .filter(Boolean); // Filter out unchanged steps

      const versionName = "ManualEdit"; // Change to a simpler name

      // Submit HumanFeedback with corrected steps
      await submitFeedback(
        blueprint,
        "positive",
        editedSteps, // Full steps after manual edits
        index,
        versionName,
        correctedSteps
      );

      // Immediately update feedback and hide buttons
      setFeedback((prev) => ({
        ...prev,
        [index]: { verified: true },
      }));

      setBlueprints((prev) => {
        const updated = [...prev];
        updated[index] = {
          ...updated[index],
          versions: [
            ...(updated[index].versions || []),
            { version_name: "Manually Edited Blueprint", steps: editedSteps },
          ],
        };
        return updated;
      });

      setIsSubmitting(false);
    }
    window.location.reload();
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
        Blueprints Review
      </h1>
      {blueprints.map((blueprint, index) => (
        <div
          key={index}
          className="bg-white rounded-lg p-6 shadow-md mb-6 border border-gray-200"
        >
          {/* Query */}
          <div
            className="flex justify-between items-center cursor-pointer mb-4"
            onClick={() =>
              setOpenQueries((prev) => ({ ...prev, [index]: !prev[index] }))
            }
          >
            <h3 className="text-xl font-semibold text-gray-800">
              {index + 1}. Query: {blueprint.query}
              {blueprint.versions?.some(
                (version) => version.feedback?.status === "positive"
              ) && <span className="text-green-600 ml-2">Verified ✅</span>}
            </h3>

            <button
              className="text-gray-600 focus:outline-none"
              onClick={(e) => {
                e.stopPropagation();
                setOpenQueries((prev) => ({ ...prev, [index]: !prev[index] }));
              }}
            >
              {openQueries[index] ? "▼" : "▶"}
            </button>
          </div>

          {/* Initial Blueprint */}
          {/* Blueprint Versions */}
          {openQueries[index] && (
            <>
              {/* Blueprint Versions */}
              <div className="mb-6">
                <h4 className="text-lg font-bold text-gray-700 mb-3">
                  Blueprint Versions:
                </h4>
                {blueprint.versions && blueprint.versions.length > 0 ? (
                  blueprint.versions.map((version, versionIdx) => (
                    <div key={versionIdx} className="mb-4">
                      <ul>
                        {version.steps.map((step, stepIdx) => (
                          <li
                            key={stepIdx}
                            className="flex items-center text-base text-gray-700 mb-2"
                          >
                            {step.step}
                          </li>
                        ))}
                      </ul>
                      {version.feedback && (
                        <div className="text-sm text-gray-600 mt-2">
                          Feedback: {version.feedback.status} at{" "}
                          {new Date(
                            version.feedback.timestamp
                          ).toLocaleString()}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <>
                    <h4 className="text-lg font-bold text-gray-700 mb-3">
                      Initial Blueprint:
                    </h4>
                    <ul>
                      {blueprint.blueprint.map((step, idx) => (
                        <li
                          key={idx}
                          className="flex items-center text-base text-gray-700 mb-3"
                        >
                          {step.step}
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>

              {/* Feedback Buttons */}
              {/* Feedback Buttons */}
              {!blueprint.versions?.some(
                (version) => version.feedback?.status === "positive"
              ) && (
                <div className="flex space-x-4 mt-4">
                  <button
                    onClick={() => handleThumbsUp(blueprint, index)}
                    className="text-green-600 text-3xl focus:outline-none"
                    disabled={isSubmitting}
                  >
                    <IoIosThumbsUp />
                  </button>
                  <button
                    onClick={() => handleThumbsDown(blueprint, index)}
                    className="text-red-600 text-3xl focus:outline-none"
                    disabled={isSubmitting}
                  >
                    <IoIosThumbsDown />
                  </button>
                </div>
              )}
            </>
          )}

          {/* Loading Spinner */}
          {loadingBlueprints[index] && (
            <div className="flex items-center mb-4">
              <FiLoader className="animate-spin mr-2 text-gray-600 text-xl" />
              <p className="text-gray-600">Regenerating blueprint...</p>
            </div>
          )}

          {/* Re-Generated Blueprint */}
          {reGeneratedBlueprints[index] &&
            !manualEdits[index] &&
            !loadingBlueprints[index] && (
              <div className="mb-6">
                <h4 className="text-lg font-bold text-gray-700 mb-3">
                  New Blueprint:
                </h4>
                <ul>
                  {reGeneratedBlueprints[index].map((step, idx) => (
                    <li
                      key={idx}
                      className="flex items-center text-base text-gray-700 mb-3"
                    >
                      {step.step}
                    </li>
                  ))}
                </ul>

                {/* Feedback Buttons for New Blueprint */}
                {!blueprint.versions?.some(
                  (version) => version.feedback?.status === "positive"
                ) && (
                  <div className="flex space-x-4 mt-4">
                    <button
                      onClick={() => handleThumbsUp(blueprint, index)}
                      className="text-green-600 text-3xl focus:outline-none"
                      disabled={isSubmitting}
                    >
                      <IoIosThumbsUp />
                    </button>
                    <button
                      onClick={() => handleThumbsDown(blueprint, index)}
                      className="text-red-600 text-3xl focus:outline-none"
                      disabled={isSubmitting}
                    >
                      <IoIosThumbsDown />
                    </button>
                  </div>
                )}
              </div>
            )}

          {/* Manual Edit */}
          {manualEdits[index] && (
            <div className="mb-6">
              <h4 className="text-lg font-bold text-gray-600 mb-3">
                Edit blueprint yourself!, type inside the boxes.
              </h4>
              <ul>
                {reGeneratedBlueprints[index].map((step, idx) => (
                  <li
                    key={idx}
                    className="flex items-center text-base text-gray-700 mb-3"
                  >
                    {/* Add serial numbers dynamically */}
                    <span className="mr-3 font-bold">{idx + 1}.</span>

                    <input
                      type="text"
                      value={step.step}
                      onChange={(e) => {
                        const updated = [...reGeneratedBlueprints[index]];
                        updated[idx].step = e.target.value;
                        setReGeneratedBlueprints((prev) => ({
                          ...prev,
                          [index]: updated,
                        }));
                      }}
                      className="w-full px-6 py-2 bg-gray-300 text-gray-800 rounded focus:outline-none"
                    />
                  </li>
                ))}
              </ul>

              {/* Submit Button */}
              <div className="flex mt-4 justify-center">
                <button
                  onClick={() => {
                    handleSubmitManualEdit(blueprint, index);
                    setFeedback((prev) => ({
                      ...prev,
                      [index]: { verified: true }, // Mark as verified
                    }));
                  }}
                  className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none"
                  disabled={isSubmitting}
                >
                  Submit
                </button>
              </div>
            </div>
          )}

          {/* Feedback Buttons or Submit */}
        </div>
      ))}
    </div>
  );
}
