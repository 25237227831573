import React, { useEffect, useState } from "react";

const blogs = [
  {
    title:
      "Midjourney vs Dall-E vs Stable Diffusion: Which AI Art Generator Actually Fits Your Needs",
    category: "Tool Deep Dives",
  },
  {
    title:
      "The Designer's Guide to AI: 5 Tools That Will Transform Your Workflow",
    category: "Tool Deep Dives",
  },
  {
    title: "ChatGPT Plugins Deep Dive: Beyond the Basics",
    category: "Tool Deep Dives",
  },
  {
    title: "The Perfect AI Writing Stack: Combining Tools for Better Content",
    category: "Workflow Optimizations",
  },
  {
    title:
      "Building a Second Brain: AI Tools That Actually Make You More Creative",
    category: "Workflow Optimizations",
  },
  {
    title:
      "The Ultimate AI Research Workflow: From Question to Presentation in Half the Time",
    category: "Workflow Optimizations",
  },
  {
    title: "How a Solo Designer Competes with Agencies Using AI Tools",
    category: "Industry Spotlights",
  },
  {
    title:
      "Inside a Modern Marketing Team: The AI Stack That Powers Their Success",
    category: "Industry Spotlights",
  },
  {
    title:
      "Small Business Revolution: How Local Shops Are Using AI to Compete with Giants",
    category: "Industry Spotlights",
  },
  {
    title: "The $100K Blog Post: How One Creator Used AI to Go Viral",
    category: "Success Stories",
  },
  {
    title:
      "AI Saved My Small Business: A Restaurant Owner's Journey with Automation",
    category: "Success Stories",
  },
  {
    title: "The Freelancer's AI Journey: From Overwhelmed to Booked Out",
    category: "Success Stories",
  },
];

export default function Blog() {
  const [activeCategory, setActiveCategory] = useState("Tool Deep Dives");

  const handleScroll = () => {
    const blogElements = document.querySelectorAll(".blog-item");
    let currentCategory = "Tool Deep Dives";

    blogElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2) {
        currentCategory = element.dataset.category;
      }
    });

    setActiveCategory(currentCategory);
  };

  // Scroll to the top when the page loads
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="bg-tealcanva1 font-montserrat min-h-screen flex">
      {/* Main Content */}
      <main className="w-full lg:w-3/4 mx-auto lg:ml-20 px-6 md:px-16 lg:px-32 py-10">
        <h1 className="text-3xl md:text-5xl font-sans font-semibold text-black mb-4">
          Mindlr’s Blog
        </h1>
        <p className="text-md md:text-lg text-black font-sans">
          We explore the intersection of human creativity and AI capabilities,
          sharing
        </p>
        <p className="text-md md:text-lg underline text-black font-sans cursor-pointer mb-8">
          insights, success stories, and practical guides to help navigate the
          AI landscape.
        </p>

        {/* Blog Items */}
        <div className="space-y-8 md:space-y-12">
          {blogs.map((blog, index) => (
            <div
              key={index}
              className="block bg-tealcanva2 p-4 md:p-6 rounded-lg shadow-md hover:shadow-lg transition blog-item"
              data-category={blog.category}
            >
              <h2 className="text-lg md:text-xl text-black font-sans font-bold">
                {blog.title}
              </h2>
            </div>
          ))}
        </div>
      </main>

      {/* Sidebar */}
      <aside className="hidden lg:block w-1/5 mt-20 fixed right-0 top-20 bg-tealcanva1 p-6">
        <ul className="space-y-6">
          {[
            "Tool Deep Dives",
            "Workflow Optimizations",
            "Industry Spotlights",
            "Success Stories",
          ].map((category, index) => (
            <li
              key={index}
              className={`cursor-pointer ${
                activeCategory === category
                  ? "font-bold text-black underline"
                  : "text-black"
              }`}
            >
              {category}
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
}
