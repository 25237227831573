import React, { useState } from "react";

export default function FeedbackForm({ userId }) {
  // State to manage popup visibility
  const [showFeedbackpopup, setShowFeedbackpopup] = useState(false);
  const [feedback, setFeedback] = useState("");

  // Toggle the feedback popup
  const toggleFeedbackpopup = () => {
    setShowFeedbackpopup((prev) => !prev);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/feedback/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ feedback }),
        }
      );

      if (response.ok) {
        alert("Feedback submitted successfully!");
        setFeedback(""); // Reset feedback input
        setShowFeedbackpopup(false); // Close popup
      } else {
        alert("Failed to submit feedback");
      }
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <>
      {/* The button that triggers the feedback popup */}
      <button
        onClick={toggleFeedbackpopup}
        className="bg-tealcanva9 hover:opacity-90 text-black font-poppins py-2 px-4 rounded-full shadow-lg"
      >
        Feedback
      </button>

      {/* Conditionally render the feedback popup */}
      {showFeedbackpopup && (
        <div
          // Full-screen overlay
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-5 md:px-0"
          onClick={toggleFeedbackpopup} // Clicking outside the popup closes it
        >
          <div
            // Feedback form container
            className="relative bg-white p-6 rounded-lg shadow-lg max-w-md w-full"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
          >
            <h2 className="text-xl font-poppins text-black mb-4">
              Kindly give us your feedback!
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Enter your feedback here..."
                  className="w-full p-2 border bg-slate-200 text-black rounded"
                  rows="5"
                  required
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={toggleFeedbackpopup}
                  className="bg-gray-300 text-black px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
