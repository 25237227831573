import React, { useEffect, useState } from "react";

export default function QueriesandBlueprints() {
  const [data, setData] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/fetchAllSearchHistories`
        );
        if (!response.ok) throw new Error("Failed to fetch data");

        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const toggleExpansion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="flex justify-between p-6">
      {/* Left-side User Table */}
      <div className="w-1/4 border-r border-gray-300 pr-4">
        <h2 className="text-xl font-bold text-teal-600 mb-4 text-center">
          User Stats
        </h2>
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="border-b border-gray-300">
              <th className="py-2 text-sm font-bold">User</th>
              <th className="py-2 text-sm font-bold">SM</th>
              <th className="py-2 text-sm font-bold">CM</th>
            </tr>
          </thead>
          <tbody>
            {data.map((user, index) => (
              <tr key={index} className="border-b border-gray-200">
                <td className="py-2 text-sm">
                  {user.name} <br />
                  <span className="text-xs text-gray-500">{user.email}</span>
                </td>
                <td className="py-2 text-sm text-center">
                  {user.searchesMade}
                </td>
                <td className="py-2 text-sm text-center">
                  {user.customizationsMade}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Right-side Query and Blueprint Cards */}
      <div className="w-3/4 pl-4">
        <h1 className="text-3xl font-bold text-center text-teal-600 mb-6">
          Recorded Queries and Blueprints
        </h1>
        {data.length > 0 ? (
          <div className="space-y-6">
            {data.map((user, userIndex) =>
              user.searchHistory.map((entry, index) => (
                <div
                  key={`${userIndex}-${index}`}
                  className="border border-gray-300 rounded-lg p-6 bg-white shadow-lg"
                >
                  {/* Query as Heading */}
                  <div
                    onClick={() => toggleExpansion(`${userIndex}-${index}`)}
                    className="cursor-pointer flex justify-between items-center"
                  >
                    <h2 className="text-lg font-bold text-gray-800">
                      {entry.query}{" "}
                      <span className="text-sm text-gray-500">
                        (No. of Customizations: {entry.customizations.length})
                      </span>
                    </h2>
                    <span className="text-gray-600">
                      {expandedIndex === `${userIndex}-${index}` ? "▲" : "▼"}
                    </span>
                  </div>

                  {expandedIndex === `${userIndex}-${index}` && (
                    <div className="mt-4">
                      {/* User Details */}
                      <div className="mb-4">
                        <p className="text-lg text-black font-bold">
                          User: {user.name}
                        </p>
                        <p className="text-lg text-black">
                          Email: {user.email}
                        </p>
                      </div>

                      {/* Initial Blueprint */}
                      <div className="mb-6">
                        <h3 className="text-md font-bold text-gray-600 mb-2">
                          Initial Blueprint
                        </h3>
                        <div className="w-full p-4 rounded-lg bg-gray-100 shadow-md">
                          {entry.blueprint.map((step, stepIndex) => (
                            <div key={stepIndex} className="mb-4">
                              <div className="flex items-center">
                                {step.tool_name !== "N/A" && (
                                  <a
                                    href={step.tool_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="w-12 h-12 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                  >
                                    {step.tool_logo ? (
                                      <img
                                        src={step.tool_logo}
                                        alt={step.tool_name}
                                        className="w-full h-full object-contain rounded-full"
                                      />
                                    ) : (
                                      <span className="text-xs font-bold">
                                        {step.tool_name[0]}
                                      </span>
                                    )}
                                  </a>
                                )}
                                <div className="ml-4 flex-1 bg-gray-200 p-2 rounded-lg border border-gray-400">
                                  <p className="text-sm text-gray-800 font-semibold">
                                    {step.instruction}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Customizations */}
                      {entry.customizations.length > 0 && (
                        <div>
                          <h3 className="text-md font-bold text-gray-600 mb-2">
                            Customizations
                          </h3>
                          {entry.customizations.map(
                            (customization, customizationIndex) => (
                              <div
                                key={customizationIndex}
                                className="mb-6 border border-gray-300 p-4 rounded-lg bg-gray-100"
                              >
                                <h4 className="text-sm font-bold text-gray-600">
                                  Customization Prompt:{" "}
                                  {customization.customizePrompt}
                                </h4>
                                <div className="mt-4 w-full p-4 rounded-lg bg-white shadow-md">
                                  {customization.customizedBlueprint.map(
                                    (customStep, customStepIndex) => (
                                      <div
                                        key={customStepIndex}
                                        className="mb-4"
                                      >
                                        <div className="flex items-center">
                                          {customStep.tool_name !== "N/A" && (
                                            <a
                                              href={customStep.tool_link}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="w-12 h-12 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                                            >
                                              {customStep.tool_logo ? (
                                                <img
                                                  src={customStep.tool_logo}
                                                  alt={customStep.tool_name}
                                                  className="w-full h-full object-contain rounded-full"
                                                />
                                              ) : (
                                                <span className="text-xs font-bold">
                                                  {customStep.tool_name[0]}
                                                </span>
                                              )}
                                            </a>
                                          )}
                                          <div className="ml-4 flex-1 bg-gray-200 p-2 rounded-lg border border-gray-400">
                                            <p className="text-sm text-gray-800 font-semibold">
                                              {customStep.instruction}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ))
            )}
          </div>
        ) : (
          <p className="text-center text-gray-600">No data available</p>
        )}
      </div>
    </div>
  );
}
