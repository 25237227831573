import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./features/user/userSlice";
import videoReducer from "./features/videos/videoSlice";

export const store = configureStore({
  reducer: {
    user: userReducer, // user slice
    videos: videoReducer, // video slice
  },
  // Add the middleware explicitly
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true, // Ensure redux-thunk is included
    }),
});
