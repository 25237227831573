import React from "react";

const VideoCard2 = ({ video }) => {
  return (
    <div className="video-card p-4 bg-none rounded-md shadow-md">
      {/* Video Title */}
      <h3
        className="video-title text-black font-poppins text-lg font-bold mb-2"
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {video.title}
      </h3>
      {/* YouTube Iframe */}
      <iframe
        className="w-full h-48 md:h-56"
        src={`https://www.youtube.com/embed/${video.videoId}`}
        title={video.title}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoCard2;
