import Header from "../components/Header";
import React, { useEffect, useState } from "react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";

export default function BluePrintExamples() {
  const [blueprintExamples, setBlueprintExamples] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null); // Track expanded blueprint

  useEffect(() => {
    fetchBlueprintExamples();
  }, []);

  const fetchBlueprintExamples = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/fetchblueprintsexamples`
      );
      if (!response.ok) throw new Error("Failed to fetch blueprint examples");

      const data = await response.json();
      setBlueprintExamples(data);
    } catch (error) {
      console.error("Error fetching blueprint examples:", error);
    }
  };

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index); // Expand/Collapse the blueprint
  };

  const handleFeedback = (index, feedbackType) => {
    // Logic to handle feedback (like/dislike)
    console.log(`Feedback for blueprint ${index}: ${feedbackType}`);
  };

  const handleCustomizeBlueprint = (example) => {
    // Logic for customizing the blueprint
    console.log("Customizing blueprint:", example);
  };

  return (
    <>
      <Header />
      <div className="bg-gray-100 min-h-screen p-8">
        <h1 className="text-3xl mb-4 font-dmsans font-semibold text-black  text-center">
          Blueprint Examples
        </h1>

        <div className="space-y-6">
          {blueprintExamples.map((example, index) => (
            <div
              key={example._id}
              className="w-5/5 mx-auto rounded-3xl p-4 bg-white border-2 border-tealcanva4"
            >
              {/* Query */}
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleExpand(index)}
              >
                <h2 className="text-lg font-sans text-center text-black px-4 py-0 inline-block">
                  Query : {example.query}
                </h2>
                <span className="text-xl font-bold text-gray-600">
                  {expandedIndex === index ? "▲" : "▼"}
                </span>
              </div>

              {/* Expanded Blueprint */}
              {expandedIndex === index && (
                <div className="mt-4 p-4 rounded-3xl bg-tealcanva6">
                  <div className="flex justify-between items-center mb-4 relative">
                    <h2 className="text-lg font-sans text-center text-black border-2 border-black rounded-full px-4 py-0 inline-block">
                      {example.blueprint.blueprint_title}
                    </h2>

                    {/* Thumbs Up/Down Buttons */}
                    <div className="absolute bottom-0 right-4 flex space-x-4">
                      <div className="text-green-500 text-2xl hover:text-green-400 focus:outline-none">
                        👍
                      </div>
                      <div className="text-red-500 text-2xl hover:text-red-400 focus:outline-none">
                        👎
                      </div>
                    </div>

                    {/* Credits Button */}
                    <div
                      className="absolute mt-1 right-5 text-black text-xs font-bold px-2 py-1 rounded-full shadow-md"
                      style={{ top: "24px" }}
                    >
                      10 credits
                    </div>
                  </div>

                  {/* Blueprint Steps */}
                  {example.blueprint.steps.map((step, stepIndex) => (
                    <div
                      key={stepIndex}
                      className="flex items-center mb-6 relative"
                    >
                      {/* Tool Information */}
                      {step.tools && step.tools.length > 0 && (
                        <div className="flex flex-col items-center w-16">
                          <a
                            href={step.tools[0].tool_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="w-10 h-10 rounded-full bg-gray-600 text-white flex items-center justify-center relative z-10 border border-gray-500"
                          >
                            <img
                              src={step.tools[0].tool_logo}
                              alt={step.tools[0].tool_name}
                              className="w-full h-full object-contain rounded-full"
                            />
                          </a>
                          <div className="text-xs text-black font-bold font-poppins mt-1 text-center">
                            {step.tools[0].tool_name}
                          </div>
                        </div>
                      )}

                      {/* Step Instruction */}
                      <div
                        className={`${
                          step.tools && step.tools.length > 0 ? "ml-4" : ""
                        } flex-1 bg-white p-2 border border-black rounded-full flex items-center justify-between`}
                      >
                        <p className="text-sm text-black font-montserrat font-semibold">
                          {step.step}
                        </p>
                      </div>

                      {/* Tool Switch Icon */}
                      {step.tools && step.tools.length > 0 && (
                        <div className="relative group">
                          <FaArrowRightArrowLeft className="text-gray-600 ml-2" />
                        </div>
                      )}

                      {stepIndex < example.blueprint.steps.length - 1 && (
                        <div
                          className="absolute left-8 border-l-2 border-black border-2 z-0"
                          style={{
                            top: "calc(100% + 4px)", // Position slightly below the current step
                            height: "28px", // Adjust height to fit between steps
                          }}
                        ></div>
                      )}
                    </div>
                  ))}

                  {/* Customize Blueprint Button */}
                  <div className="flex justify-end">
                    <div className="mt-2 px-3 py-2 bg-white text-black rounded-full border border-black font-sans text-md font-semibold">
                      Customize Blueprint
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
