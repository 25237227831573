import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RxCrossCircled } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserData } from "../features/user/userSlice";
import { fetchUserVideos } from "../features/videos/videoSlice"; // Adjust the path as needed

const VideoUploadPopup = ({ onClose }) => {
  //Redux implemented here :
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userStatus = useSelector((state) => state.user.status);

  useEffect(() => {
    if (userStatus === "idle") {
      // Means we haven't fetched yet
      dispatch(fetchUserVideos());
    }
  }, [userStatus, dispatch]);

  if (userStatus === "loading") {
    console.log("loading");
  }

  // If there's an error
  if (userStatus === "failed") {
    console.log("Error loading user data");
  }

  // If user is not logged in, maybe userData is null
  if (!userData) {
    console.log("No user data found (not logged in?)");
  }

  const [videoFile, setVideoFile] = useState(null);
  const [tagLine, setTagLine] = useState("");
  const [aiTool, setAiTool] = useState("");
  const [description, setDescription] = useState("");
  const [caption, setCaption] = useState("");
  const [uploading, setUploading] = useState(false);

  const showErrorToast = (message) => {
    toast.error(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: true,
    });
  };

  // Success Toast
  const showSuccessToast = (message) => {
    toast.success(message, {
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnHover: false,
      closeOnClick: true,
    });
  };

  const handleFileChange = (e) => {
    setVideoFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!videoFile) {
      showErrorToast("Please select a video.");
      return;
    }

    setUploading(true);

    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("tagLine", tagLine);
    formData.append("aiTool", aiTool);
    formData.append("description", description);
    formData.append("caption", caption);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/uploadVideo`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );

      if (response.ok) {
        alert("Video uploaded successfully!"); // Show success toast only

        dispatch(fetchUserVideos(userData._id));
      } else {
        alert("Failed to upload video.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("An error occurred while uploading the video.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div
        className="fixed inset-0 bg-white bg-opacity-75 flex justify-center items-center z-50"
        style={{
          height: "100%", // Ensure full height covers the entire screen
          margin: "0", // Remove margin to avoid pushing the modal content down
          overflowY: "auto", // Allow scrolling if content overflows
        }}
      >
        {/* Close Button */}
        <button
          className="absolute top-4 right-4 w-8 h-8 flex items-center justify-center text-black text-3xl z-10"
          onClick={onClose}
        >
          <RxCrossCircled />
        </button>

        <div className="bg-tealcanva6 rounded-lg w-full max-w-5xl p-8 mt-10 text-black">
          {/* Key Guidelines Section */}
          <div className="relative bg-black p-3 md:p-5 rounded-3xl mb-6 font-sans text-white items-start ">
            <h3 className="text-xs md:text-xl font-bold mb-2 md:mb-4 md:ml-96">
              Key Guidelines
            </h3>
            <p className="text-xs md:text-md mb-1">
              <strong>Authenticity:</strong> Please share genuine use cases
              where you've personally used the AI tool.
            </p>

            <p className="text-xs md:text-md">
              <strong>Content Review:</strong> All posts and videos will be
              reviewed by our filtering algorithm to ensure compliance with our
              policies.
            </p>
          </div>

          {/* Video Upload and Input Fields Section */}
          <div className="flex flex-col md:flex-row items-start space-y-6 md:space-y-0 md:space-x-6">
            {/* Left Section (Video Upload) */}
            <div className="w-full md:w-1/2 flex flex-col items-center bg-graycanva3 p-4 rounded-3xl">
              <label className="block text-lg text-white font-sans font-bold mb-4">
                Select Video
              </label>
              <input
                type="file"
                accept="video/*"
                onChange={handleFileChange}
                className="w-full bg-white p-2 rounded-lg border border-gray-300"
              />
              <button
                className="hidden md:block mt-4 px-4 py-2 bg-tealcanva9 text-black rounded-full"
                onClick={() =>
                  document.querySelector("input[type='file']").click()
                }
              >
                Browse
              </button>
            </div>

            {/* Right Section (Input Fields) */}
            <div className="w-full md:w-1/2">
              <div className="mb-1">
                <input
                  type="text"
                  value={tagLine}
                  onChange={(e) => setTagLine(e.target.value)}
                  className="bg-graycanva4 text-white text-md w-full p-2 rounded-lg border border-gray-300"
                  placeholder="Tagline"
                />
              </div>
              <div className="mb-1">
                <input
                  type="text"
                  value={aiTool}
                  onChange={(e) => setAiTool(e.target.value)}
                  className="bg-graycanva4 text-white w-full p-2 rounded-lg border border-gray-300"
                  placeholder="AI Tool"
                />
              </div>
              <div className="mb-0">
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="bg-graycanva4 text-white w-full p-2 rounded-lg border border-gray-300"
                  placeholder="Description"
                />
              </div>
              <div className="mb-0">
                <textarea
                  value={caption}
                  onChange={(e) => setCaption(e.target.value)}
                  className="bg-graycanva4 text-white w-full p-2 rounded-lg border border-gray-300"
                  placeholder="Caption"
                />
              </div>
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-end mb-8">
            <button
              onClick={handleUpload}
              className={`bg-tealcanva9  text-black px-6 py-1 rounded-full ${
                uploading ? "opacity-50" : ""
              }`}
              disabled={uploading}
            >
              {uploading ? "Uploading..." : "Post"}
            </button>
          </div>

          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default VideoUploadPopup;
