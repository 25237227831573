import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

const Disclaimer = ({ onAgree, onDisagree }) => {
  const navigate = useNavigate();
  const bottomRef = useRef(null);

  const handleDisagree = async () => {
    try {
      localStorage.removeItem("disclaimerShown");
      const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`, {
        method: "POST",
        credentials: "include",
      });
      if (response.ok) {
        navigate("/sign-in");
      } else {
        console.error("Failed to log out:", response.statusText);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    // Detect mobile devices based on screen width (less than 768px)
    const isMobile = window.innerWidth <= 768;

    // Scroll to the bottom automatically on mobile
    if (isMobile && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="fixed inset-0 text-sm md:text-normal mt-28 md:mt-0  flex items-center justify-center bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center w-full max-w-lg mx-4 md:mx-auto max-h-full overflow-y-auto">
        <h2 className="text-xl font-bold mb-4 md:text-2xl">Disclaimer</h2>
        <div className="max-h-96 overflow-y-auto pb-20">
          {/* Added padding-bottom */}
          <p className="mb-6 text-black font-poppins text-sm md:text-base">
            Welcome to the Mindlr Alpha version. As this is an early release, we
            want to ensure that you fully understand the terms of use and the
            nature of this testing phase. By accessing or participating in the
            Alpha testing, you acknowledge the following.
          </p>
          <p className="mb-6 text-black font-poppins text-sm md:text-base">
            We have gathered data from publicly available social media platforms
            to enhance and test the search functionality of the Mindlr platform.
            This data is used strictly for evaluating the system’s performance.
            At no point will this data be commercialized or shared with third
            parties. Our primary objective in utilizing this data is to better
            understand how the platform performs and to improve its
            capabilities. The data you interact with during this Alpha phase has
            been collected solely for internal testing purposes only.
          </p>
          <p className="mb-0 md:mb-6 text-black font-poppins text-sm md:text-base">
            Mindlr is currently in development. Core features are operational,
            but some are still in development or may have limitations. Finally,
            we would like to note that, any data used or generated during this
            period will be treated with care and we kindly ask that you do not
            disclose these to others, as some features are still under
            development and not intended for public release yet.
          </p>
          {/* Reference to the bottom */}
          <div ref={bottomRef}></div>
        </div>
        <div className="flex justify-around mt-0 md:mt-4">
          <button
            onClick={onAgree}
            className="bg-green-500 text-white px-4 py-2 rounded-full"
          >
            Agree
          </button>
          <button
            onClick={handleDisagree}
            className="bg-red-500 text-white px-4 py-2 rounded-full"
          >
            Disagree
          </button>
        </div>
      </div>
    </div>
  );
};

export default Disclaimer;
