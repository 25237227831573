import React from "react";

export default function TermsOfUse() {
  return (
    <div className="min-h-screen bg-white text-gray-800 font-serif p-6 md:p-2">
      <div className="max-w-5xl mx-auto bg-white rounded-xl p-8 md:p-12">
        {/* Page Title */}
        <h1 className="text-4xl font-bold mb-4 text-center text-black">
          Terms of Use
        </h1>
        <p className="text-lg text-gray-500 mb-8 text-center">
          Last Updated: <span className="font-medium">2025-01-16</span>
        </p>

        {/* Terms of Use Content */}
        <div className="space-y-8 leading-relaxed">
          <p>
            Welcome to <strong>Mindlr</strong> (“we,” “us,” or “our”). By
            accessing or using our website and any related services, you (“user”
            or “you”) agree to be bound by the following Terms of Use (“Terms”).
            If you do not agree, please discontinue using our services.
          </p>
          <hr className="border-t border-gray-300 my-4" />

          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              1. Acceptance of Terms
            </h2>
            <p>
              By accessing our platform, you acknowledge that you have read,
              understood, and agree to these Terms, as well as our Privacy
              Policy.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">2. Eligibility</h2>
            <p>
              You must be at least 13 years old (or the age of majority in your
              jurisdiction) to use our services. By using Mindlr, you represent
              that you meet these eligibility requirements.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">3. User Accounts</h2>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Registration:</strong> When creating an account, you
                agree to provide accurate and up-to-date information.
              </li>
              <li className="text-lg">
                <strong>Security:</strong> You are responsible for maintaining
                the confidentiality of your login credentials and for all
                activities under your account.
              </li>
              <li className="text-lg">
                <strong>Termination:</strong> We reserve the right to suspend or
                terminate user accounts for any violation of these Terms.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">4. User Conduct</h2>
            <p>You agree not to:</p>
            <ol className="list-decimal list-inside space-y-2">
              <li className="text-lg">
                Post or transmit any unlawful, infringing, defamatory, or
                harmful content.
              </li>
              <li className="text-lg">
                Interfere with or disrupt our services, servers, or networks.
              </li>
              <li className="text-lg">
                Attempt to access another user’s account without their
                permission.
              </li>
            </ol>
            <p className="mt-3">
              We reserve the right to remove content that violates these Terms
              or is otherwise objectionable.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              5. Intellectual Property
            </h2>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Ownership:</strong> All website content, including text,
                graphics, logos, and software, is owned by or licensed to Mindlr
                and is protected by intellectual property laws.
              </li>
              <li className="text-lg">
                <strong>License:</strong> We grant you a non-exclusive,
                revocable license to use our platform for personal,
                non-commercial purposes. You may not copy, modify, distribute,
                or sell any part of our content without explicit permission.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              6. User-Generated Content
            </h2>
            <ul className="list-disc list-inside space-y-2">
              <li className="text-lg">
                <strong>Ownership & License:</strong> You retain ownership of
                any content you post (e.g., comments, reviews). By posting, you
                grant Mindlr a non-exclusive, worldwide, royalty-free license to
                use, reproduce, and display such content in connection with our
                services.
              </li>
              <li className="text-lg">
                <strong>Responsibility:</strong> You are solely responsible for
                content you submit and any consequences of publishing it on our
                platform.
              </li>
            </ul>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 7 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              7. Disclaimer of Warranties
            </h2>
            <p className="text-lg">
              Our services are provided “as is” and “as available,” without any
              warranties of any kind, whether express or implied. We do not
              guarantee that our platform will be uninterrupted or error-free.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 8 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              8. Limitation of Liability
            </h2>
            <p className="text-lg">
              To the fullest extent permitted by law, Mindlr and its affiliates
              will not be liable for any indirect, incidental, special, or
              consequential damages arising out of or in connection with your
              use of our services.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 9 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">9. Indemnification</h2>
            <p className="text-lg">
              You agree to defend, indemnify, and hold Mindlr harmless from all
              claims, liabilities, damages, and expenses (including attorneys’
              fees) arising from your violation of these Terms or your misuse of
              our services.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 10 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">10. Governing Law</h2>
            <p className="text-lg">
              These Terms are governed by the laws of the state of Delaware
              (U.S.), without regard to conflict-of-law principles. Any disputes
              shall be resolved in the appropriate courts located in Delaware,
              unless otherwise required by applicable law.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 11 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">
              11. Changes to These Terms
            </h2>
            <p className="text-lg">
              We may modify these Terms from time to time. Any updates will be
              posted on our website with a revised “Last Updated” date. If you
              continue using our services after changes become effective, you
              accept those revised Terms.
            </p>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Section 12 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4">12. Contact Us</h2>
            <p>If you have questions about these Terms, please contact us:</p>
            <address className="mt-3">
              <strong>Mindlr</strong> <br />
              Attn: Naveen Sai Ganta <br />
              <a
                href="https://mindlr.ai/contact-us"
                className="text-blue-600 underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://mindlr.ai/contact-us
              </a>
            </address>
            <hr className="border-t border-gray-300 my-4" />
          </section>

          {/* Final Statement */}
          <p className="text-xl text-start mt-8">
            <strong>
              By using Mindlr, you acknowledge that you have read, understood,
              and agreed to these Terms of Use.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}
