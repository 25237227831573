import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// Async thunk to fetch user-specific videos
export const fetchUserVideos = createAsyncThunk(
  "videos/fetchUserVideos", // Action type
  async (userId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/videos?userId=${userId}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch videos");
      }

      const videos = await response.json();
      return videos;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  videos: [], // Stores the list of videos
  status: "idle", // "idle" | "loading" | "succeeded" | "failed"
  error: null, // Error message, if any
};

const videoSlice = createSlice({
  name: "videos",
  initialState,
  reducers: {
    // Add reducers here if needed in the future
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserVideos.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchUserVideos.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.videos = action.payload; // Store the fetched videos
      })
      .addCase(fetchUserVideos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to fetch videos";
      });
  },
});

export default videoSlice.reducer;
